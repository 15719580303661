<template>
  <div class="users">
    <FirstAccessTabs></FirstAccessTabs>

        <!-- <div class="title text-white">Create New Plan</div> -->
    <div class="relative mb-3">
      <el-row :gutter="40" class="m-top-3">
        <el-col :span="24" :xs="24">
          <div class="branding-logo-position">
            <div class="positions title">
              <label class=" key-label">Plan Name</label>
            </div>
            <div class="positions">
              <input
                class="form-control with_arrow_190"
                id="user-first-name"
                placeholder="Enter plan name"
                type="text"
                v-model="plan_name"
              />
            </div>
            <span class="error" v-if="validation.type === 'plan_name'">
              <img
                alt
                class="warning-icon"
                src="../../assets/img/auth/warning.svg"
              />
              {{ validation.message }}</span
            >
          </div>

          <div class="branding-logo-position">
            <div class="positions title">
              <label class=" key-label">Plan Type</label>
            </div>
            <div class="positions">
              <el-row>
                <!-- <el-col :span="1">&nbsp;</el-col> -->
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="1"
                    class="text-white"
                    v-model="plan_type"
                    :label="'free'"
                    @click="plan_type = 'free'"
                    >Free
                  </el-radio>
                </el-col>
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="2"
                    class="text-white"
                    v-model="plan_type"
                    :label="'paid'"
                    @click="plan_type = 'paid'"
                    >Paid
                  </el-radio>
                </el-col>
                
              </el-row>

              <!-- <el-row class="m-top-2">
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="10" :xs="23">
                  <el-radio
                    label="2"
                    class="text-white"
                    v-model="plan_type"
                    :label="'paid'"
                    @click="plan_type = 'paid'"
                    >Paid
                  </el-radio>
                </el-col>
              </el-row> -->
            </div>
          </div>
          <div class="branding-logo-position" v-if="plan_type !== 'free'">
            <div class="positions title">
              <label class=" key-label">Recurring</label>
            </div>
            <div class="positions">
              <el-row>
                <!-- <el-col :span="1">&nbsp;</el-col> -->
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="1"
                    v-model="recurring"
                    class="text-white"
                    :label="'never'"
                    @click="recurring = 'never'"
                  >
                    Never
                  </el-radio>
                </el-col>
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="2"
                    v-model="recurring"
                    class="text-white"
                    :label="'monthly'"
                    @click="recurring = 'monthly'"
                  >
                    Monthly
                  </el-radio>
                </el-col>
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="2"
                    v-model="recurring"
                    class="text-white"
                    :label="'annually'"
                    @click="recurring = 'annually'"
                  >
                    Annually
                  </el-radio>
                </el-col>
              </el-row>

             
            </div>
          </div>
          <div class="branding-logo-position" v-if="plan_type !== 'free'">
            <div class="positions title">
              <label class=" key-label">Free Trial</label>
            </div>
            <div class="positions">
              <el-row>
                <!-- <el-col :span="1">&nbsp;</el-col> -->
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="1"
                    v-model="free_trial"
                    class="text-white"
                    :label="'none'"
                    @click="free_trial = 'none'"
                    >None
                  </el-radio>
                </el-col>
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="2"
                    v-model="free_trial"
                    class="text-white"
                    :label="'7_days_free'"
                    @click="free_trial = '7_days_free'"
                    >7 Days Free
                  </el-radio>
                </el-col>
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="2"
                    v-model="free_trial"
                    class="text-white"
                    :label="'14_days_free'"
                    @click="free_trial = '14_days_free'"
                    >14 Days Free
                  </el-radio>
                </el-col>
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="2"
                    v-model="free_trial"
                    class="text-white"
                    :label="'30_days_free'"
                    @click="free_trial = '30_days_free'"
                    >30 Days Free
                  </el-radio>
                </el-col>
              </el-row>
            </div>
          </div>
           <el-row>
                
              <el-col :span="12" :xs="24">
                <div class="branding-logo-position" v-if="plan_type !== 'free'">
                  <div class="positions title">
                    <label class=" key-label">FEE (US $)</label>
                  </div>
                  <div class="positions">
                    <input
                      class="form-control with_arrow_190"
                      id="user-last-name"
                      placeholder="Enter amount of plan"
                      type="text"
                      v-model="fees"
                    />
                  </div>
                  <span class="error" v-if="validation.type === 'fees'">
                    <img
                      alt
                      class="warning-icon"
                      src="../../assets/img/auth/warning.svg"
                    />
                    {{ validation.message }}</span
                  >
                </div>
              </el-col>
              <el-col :span="12" :xs="24">
                <div class="branding-logo-position">
                  <div class="positions title">
                    <label class=" key-label">Video count</label>
                  </div>
                  <div class="positions mr-15">
                    <input
                      class="form-control with_arrow_190"
                      id="user-last-name"
                      placeholder="0"
                      type="number"
                      min="0"
                      v-model="video_count"
                    />
                  </div>
                </div>
              </el-col>
			<el-col :span="24" :xs="24">
				<div class="branding-logo-position">
					<div class="positions title">
					<label class=" key-label">Video source</label>
					</div>
					<div class="positions text-white">
					<el-checkbox-group v-model="videoSource">
						<el-checkbox label="Vimeo" class="text-white"></el-checkbox>
						<el-checkbox label="Youtube" class="text-white"></el-checkbox>
						<el-checkbox label="Wistia" class="text-white"></el-checkbox>
						<el-checkbox label="MP4" class="text-white"></el-checkbox>
						<el-checkbox label="M3U8" class="text-white"></el-checkbox>
						<el-checkbox label="Live" class="text-white"></el-checkbox>
					</el-checkbox-group>
					</div>
				</div>
			</el-col>
			<el-col :span="24" :xs="24">
				<div class="branding-logo-position">
					<div class="positions title">
					<label class=" key-label">Player Skin</label>
					</div>
					<div class="positions text-white">
					<el-checkbox-group v-model="playerSkin">
						<el-checkbox label="AERO" class="text-white"></el-checkbox>
						<el-checkbox label="BOLD" class="text-white"></el-checkbox>
						<el-checkbox label="CLEAN" class="text-white"></el-checkbox>
						<el-checkbox label="MODERN" class="text-white"></el-checkbox>
					</el-checkbox-group>
					</div>
				</div>
			</el-col>
			<el-col :span="8" :xs="12">
				<div class="branding-logo-position">
					<div class="positions title">
					<label class=" key-label">Interaction Tools</label>
					</div>
					<div class="positions text-white">
					<el-switch
						v-model="interaction_tools"
						active-color="#13ce66"
						inactive-color="#ff4949"
					>
					</el-switch>
					</div>
				</div>
			</el-col>
			<el-col :span="8" :xs="12">
				<div class="branding-logo-position">
					<div class="positions title">
						<label class=" key-label">Video Behaviors</label>
					</div>
					<div class="positions text-white">
					<el-switch
						v-model="video_behaviours"
						active-color="#13ce66"
						inactive-color="#ff4949"
					>
					</el-switch>
					</div>
				</div>
			</el-col>
			<el-col :span="8" :xs="12">
				<div class="branding-logo-position">
					<div class="positions title">
					<label class="key-label"
						>Dynamic Video Funnels</label
					>
					</div>
					<div class="positions text-white">
					<el-switch
						v-model="dynamic_video_funnel"
						active-color="#13ce66"
						inactive-color="#ff4949"
					>
					</el-switch>
					</div>
				</div>
		  	</el-col>
			  <el-col :span="24" :xs="24" style="margin-top:25px">
				  <span class="textUppercase text-white">Add User Webhook</span>
			  </el-col>
			  <el-col :span="6" :xs="24">
				  
				<div class="branding-logo-position">
					<div class="positions title">
						<label class=" key-label">Method</label>
					</div>
					<div class="positions text-white">
					
						<el-select
						v-model="add_user_webhook_method"
						placeholder="Select"
						>
						<el-option label="GET" value="GET">GET</el-option>
						<el-option label="POST" value="POST">POST</el-option>
						</el-select>
					
					</div>
				</div>
  			</el-col>
			<el-col :span="12" :xs="24">
				<div class="branding-logo-position">
					<div class="positions title">
						<label class=" key-label">Url</label>
					</div>
						<div class="positions text-white">
							<input
							class="expand field"
							id="user-first-name"
							placeholder="Enter webhook url"
							type="text"
							v-model="add_user_webhook"
							/>
							<span
							class="error"
							v-if="validation.type === 'add_user_webhook'"
							>
							<img
								alt
								class="warning-icon"
								src="../../assets/img/auth/warning.svg"
							/>
							{{ validation.message }}</span
							>
						
						</div>
				</div>
	  		</el-col>
			
			<el-col :span="6" :xs="24">
          		<div class="branding-logo-position" v-if="add_user_webhook_method == 'POST'">
					<div class="positions title">
						<label class=" key-label">Payload</label>
					</div>
            		<div class="positions text-white">
						<div>
							<textarea
								class="expand field"
								id="user-first-name"
								placeholder="Enter first name"
								type="text"
								v-model="add_user_webhook_payload"
							/>
							<span
							class="error"
							v-if="validation.type === 'add_user_webhook_payload'"
							>
							<img
								alt
								class="warning-icon"
								src="../../assets/img/auth/warning.svg"
							/>
							{{ validation.message }}</span
							>
						</div>
					</div>
				</div>
			</el-col>
			  <el-col :span="24" :xs="24" style="margin-top:25px">
				  <span class="textUppercase text-white">Remove User Webhook</span>
			  </el-col>
			  <el-col :span="6" :xs="24">
				<div class="branding-logo-position">
					<div class="positions title">
					<label class=" key-label">Method</label>
					</div>
					<div class="positions text-white">
					<div>
						<el-select
						v-model="remove_user_webhook_method"
						placeholder="Select"
						>
						<el-option label="GET" value="GET">GET</el-option>
						<el-option label="POST" value="POST">POST</el-option>
						</el-select>
					</div>
					</div>
          		</div>
  			</el-col>
			<el-col :span="12" :xs="24">
				<div class="branding-logo-position">
					<div class="positions title">
						<label class=" key-label">Url</label>
					</div>
					<div class="positions text-white">
						<input
							class="expand field"
							id="user-first-name"
							placeholder="Enter webhook url"
							type="text"
							v-model="remove_user_webhook"
						/>
						<span
							class="error"
							v-if="validation.type === 'remove_user_webhook'"
						>
							<img
							alt
							class="warning-icon"
							src="../../assets/img/auth/warning.svg"
							/>
							{{ validation.message }}</span
						>
					</div>
				</div>
			</el-col>
			
			<el-col :span="6" :xs="24">
				<div class="branding-logo-position" v-if="remove_user_webhook_method == 'POST'">
					<div class="positions title">
						<label class=" key-label">Payload</label>
					</div>
					<div class="positions text-white">
						<div>
							<textarea
							class="expand field"
							id="user-first-name"
							placeholder="Enter first name"
							type="text"
							v-model="remove_user_webhook_payload"
							/>
							<span
							class="error"
							v-if="validation.type === 'remove_user_webhook_payload'"
							>
							<img
								alt
								class="warning-icon"
								src="../../assets/img/auth/warning.svg"
							/>
							{{ validation.message }}</span
							>
						</div>
					</div>
				</div>
		  	</el-col>
		  </el-row>
        </el-col>
      </el-row>
    </div>
    <!-- <el-button
      type="custom"
      class="button__cancle"
      @click="$router.push('/whitelabel/plans')"
      >Cancel
    </el-button> -->
    <el-button type="custom" class="button__upload mt-3" @click="savePlan()">
      Create Plan
    </el-button>

    <div class="plan-listing mt-5">
      <!-- <div class="user-header">
        <div class="left-side">
          <div class="title">
            My Plans
            <span class="total text-white">{{ this.plans.length }}</span>
          </div>
        </div>
        <div class="right-side">
          <div class="text-uppercase">filter</div>
          <SearchQuery :tip="''" @query="filter"></SearchQuery>
          <div>
            <el-button
              type="custom"
              class="button__upload"
              round
              @click="$router.push('/whitelabel/plans/create')"
          >
            Create Plan
          </el-button>
          </div>
        </div>
      </div> -->
      <div class="user-list-table">
        <div class="table-header text-white">
          <div class="contact-row">Plan Name</div>
          <div class="status-row">Recurring</div>
          <div class="plan-row">Cost</div>
          <!-- <div class="joined-row">Users</div>
          <div class="last-activity-row">Revenue</div> -->
          <!-- <div class="asset-row">Cancelled</div> -->
          <!-- <div class="action-row">actions</div> -->
        </div>
        <div class="table-body">
          <div class="table-row" v-for="plan in plans" :key="plan.id">
            <div class="contact-row">
              <p class="mb-0 name text-white">{{ plan.plan_name }}</p>
              <p class="text-white">{{ plan.plan_id }}</p>
            </div>
            <div class="status-row color-green textCapital">
              {{ plan.recurring }}
            </div>
            <div class="plan-row text-white">${{ plan.cost }}</div>
            <!-- <div class="joined-row text-white">{{ plan.users_count }}</div>
            <div class="last-activity-row text-white">500</div> -->
            <!-- <div class="asset-row text-white">
              <p class="text-white">85</p>
            </div> -->
            <!-- <div class="action-row">
              <button
                class="color-pink"
                @click="$router.push('/whitelabel/plans/edit/' + plan.id)"
              >
                Edit
              </button>
              <button
                class="color-red"
                @click="$router.push('/whitelabel/plans/move/' + plan.id)"
              >
                Move Users
              </button>
               <button class="color-green">Delete</button> 
            </div> -->
          </div>
        </div>
      </div>
      <pagination
        @getVideos="getPlans"
        :pagination="paginationData"
      ></pagination>
    </div>
     <div
          class="mt-4"
         
        >
            <el-button type="custom" class="button__upload"  style="float: left"
          @click="$router.push('/whitelabel/beginning/smtp-integration')"> Back </el-button>
          <el-button type="custom" class="button__upload"  style="float: right"
          @click="finish"> Finish </el-button>

        </div>
  </div>
</template>
<script>
import Pagination from "../../components/Common/Pagination.vue";
import FirstAccessTabs from "./tabs.vue";
export default {
  name: "FirstAccessPlanWebhook",
  components: {
    FirstAccessTabs,
    Pagination,
  },
  data() {
    return {
      plan_name: "",
      fees: "0",
      plan_type: "paid",
      recurring: "monthly",
      free_trial: "7_days_free",
      video_count: 0,
      videoSource: [],
      playerSkin: [],
      interaction_tools: true,
      video_behaviours: true,
      dynamic_video_funnel: true,
      validation: {
        message: "",
        type: "",
      },
      totalPlan: 3,
      showCreateUserModal: false,
      disabled: false,
      plans: {},
      paginationData: {
        view: 10,
        page: 1,
        total: 0,
        start: null,
        end: null,
      },
      add_user_webhook: "",
      add_user_webhook_method: "post",
      add_user_webhook_payload: null,

      remove_user_webhook: null,
      remove_user_webhook_method: "post",
      remove_user_webhook_payload: null,
    };
  },
  created() {
    this.getPlans();
  },

  methods: {
    finish() {
      console.log("sds dsdsd");

      let post_data = {
        url: "/whitelabel/user/status",
      };
      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          this.$router.push("/whitelabel/beginning/launch");
        })
        .catch((error) => {});
    },
    filter(keywords) {
      this.getPlans(keywords);
    },
    getPlans(keywords = "") {
      let post_data = {
        url: "/whitelabel/plans",
        data: {
          keywords: keywords,
          page: this.paginationData.page,
          view: this.paginationData.view,
        },
      };

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          this.plans = response.data.plans;

          this.paginationData.total = response.data.total;
          this.paginationData.start =
            (this.paginationData.page - 1) * this.paginationData.view + 1;
          this.paginationData.end = Math.min(
            this.paginationData.total,
            (this.paginationData.page - 1) * this.paginationData.view +
              this.paginationData.view
          );
        })
        .catch((error) => {});
    },
    addWhiteBoardUser() {
      console.log("add user");
    },
    indexMethod(index) {
      return index * 2;
    },
    validURL(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },
    savePlan() {
      if (this.plan_name === "") {
        this.validation.message = "Please enter plan name";
        this.validation.type = "plan_name";
console.log('1');
        return false;
      } else if ((this.fees === "0" || this.fees === 0) && this.plan_type !== 'free') {
        this.validation.message = "Please enter fees";
        this.validation.type = "fees";
console.log('2');
        return false;
      }
      
//       else if (
//         this.add_user_webhook === "" ||
//         this.add_user_webhook === null
//       ) {
//         this.validation.message = "Please enter url";
//         this.validation.type = "add_user_webhook";
// console.log('3');
//         return false;
//       }
      
      else if (
        this.add_user_webhook !== "" &&
        this.add_user_webhook !== null
      ) {
        var valid = this.validURL(this.add_user_webhook);

        if (!valid) {
          this.validation.message = "Please enter valid url";
          this.validation.type = "add_user_webhook";
 console.log('31');
          return false;
        }
      } 
//       else if (
//         this.add_user_webhook_method === "post" &&
//         (this.add_user_webhook_payload === null ||
//           this.add_user_webhook_payload === "")
//       ) {
//         this.validation.message = "Please enter payload";
//         this.validation.type = "add_user_webhook_payload";
//  console.log('32');
//         return false;
//       } 
      else if (
        this.add_user_webhook_payload !== "" &&
        this.add_user_webhook_payload !== null
      ) {
        var valid = this.validURL(this.add_user_webhook_payload);
        if (!valid) {
          this.validation.message = "Please enter valid payload";
          this.validation.type = "add_user_webhook_payload";
 console.log('33');
          return false;
        }
      }
//        else if (
//         this.remove_user_webhook === "" ||
//         this.remove_user_webhook === null
//       ) {
//         this.validation.message = "Please enter url";
//         this.validation.type = "remove_user_webhook";
//  console.log('34');
//         return false;
//       } 
      else if (
        this.remove_user_webhook !== "" &&
        this.remove_user_webhook !== null
      ) {
        var valid = this.validURL(this.remove_user_webhook);

        if (!valid) {
          this.validation.message = "Please enter valid url";
          this.validation.type = "remove_user_webhook";
 console.log('35');
          return false;
        }
      } 
//       else if (
//         this.remove_user_webhook_method === "post" &&
//         (this.remove_user_webhook_payload === null ||
//           this.remove_user_webhook_payload === "")
//       ) {
//         this.validation.message = "Please enter payload";
//         this.validation.type = "remove_user_webhook_payload";
//  console.log('36');
//         return false;
//       } 
      else if (
        this.remove_user_webhook_payload !== "" &&
        this.remove_user_webhook_payload !== null
      ) {
        var valid = this.validURL(this.remove_user_webhook_payload);
        if (!valid) {
          this.validation.message = "Please enter valid payload";
          this.validation.type = "remove_user_webhook_payload";
 
          return false;
        }
      } else {
        this.validation.message = "";
        this.validation.type = "";

      }



       this.validation.message = "";
        this.validation.type = "";
      var $this = this;
      let post_data = {
        url: "/whitelabel/plan/store",
        data: {
          plan_name: this.plan_name,
          fees: this.fees,
          plan_type: this.plan_type,
          recurring: this.recurring,
          free_trial: this.free_trial,
          video_count: this.video_count,
          videoSource: this.videoSource,
          playerSkin: this.playerSkin,
          interaction_tools: this.interaction_tools,
          video_behaviours: this.video_behaviours,
          dynamic_video_funnel: this.dynamic_video_funnel,
          add_user_webhook: this.add_user_webhook,
          add_user_webhook_method: this.add_user_webhook_method,
          add_user_webhook_payload: this.add_user_webhook_payload,
          remove_user_webhook: this.remove_user_webhook,
          remove_user_webhook_method: this.remove_user_webhook_method,
          remove_user_webhook_payload: this.remove_user_webhook_payload,
        },
      };

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          if (response.data.result === "success") {
            this.plan_name = "";
            this.fees = "0";
            this.plan_type = "paid";
            this.recurring = "monthly";
            this.free_trial = "7_days_free";
            this.video_count = 0;
            this.videoSource = [];
            this.playerSkin = [];
            this.interaction_tools = true;
            this.video_behaviours = true;
            this.dynamic_video_funnel = true;
            
            this.add_user_webhook = "";
            this.add_user_webhook_method = "post";
            this.add_user_webhook_payload = null;

            this.remove_user_webhook = null;
            this.remove_user_webhook_method = "post";
            this.remove_user_webhook_payload = null;
            
            // $router.push('/white-label/users')
            // this.$router.push({ name: "WhiteBoardPlans" });




            this.getPlans();
          }
          if (response.data.result === "error") {
            $this.$message({
              showClose: true,
              message: response.data.msg,
              type: "success",
            });
          }
        })
        .catch((error) => {});
    },
  },
};
</script>



  <style lang="less" scoped>
.error {
  color: red;
}
.button__cancle {
  padding: 10px 25px 9px;
  background-color: #fff;
  border: 1px solid var(--buttonThemeColor);
  font-size: 16px;
  line-height: 19px;
  color: var(--buttonThemeColor);
  border-radius: 5px;
}
.button__upload {
  padding: 10px 25px 9px;
  background-color: var(--buttonThemeColor);
  border: none;
  font-size: 16px;
  line-height: 19px;
  color: white;
  border-radius: 5px;
}
.users-main {
  padding: 40px;
  overflow: auto;
  top: 55px;
  position: absolute;
  width: auto;
}

.user-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0 !important;

  .left-side {
    .title {
      font-weight: bold;
      font-size: 22px;
    }

    .total {
      margin-left: 15px;
      font-weight: normal;
    }
  }

  .el-button--custom {
    font-weight: bolder;
  }

  .right-side {
    display: flex;
    align-items: center;

    .text-uppercase {
      font-weight: 700;
    }

    div {
      margin-right: 20px;
    }

    .button__upload {
      padding: 10px 25px 9px;
      background-color: var(--buttonThemeColor);
      border: none;
      font-size: 16px;
      line-height: 19px;
      color: white;
      border-radius: 5px;
    }
  }
}

.branding-logo-position {
  margin-top: 0px;
  .positions {
    display: flex;
    margin-top: 15px;
    margin-right: 15px;
    .el-row {
      margin-right: 15px;
    }
  }
}
label {
  font-weight: 400;
}
.user-list-table {
  min-width: auto;

  p {
    margin-bottom: 5px;
  }

  .table-header {
    display: flex;
    text-transform: uppercase;
    font-weight: bold;
	padding: 20px;
	border-bottom: 2px solid #f4f7fb;
  }

  .contact-row {
    width: 33%;

    p.name {
      font-weight: bold;
    }

    p {
      margin-bottom: 5px;
      color: #2c3e50;
    }
  }

  .status-row {
    width: 33%;
  }

  .plan-row {
    width: 33%;
  }

  .joined-row {
    width: 10%;
  }

  .last-activity-row {
    width: 17%;
  }

  .asset-row {
    width: 17%;
  }

  .table-body {
    .plan-row,
    .joined-row,
    .last-activity-row,
    .asset-row {
      color: #485050cf;
    }
  }

  .action-row {
    width: 17%;
    display: inline-grid;

    button {
      background: none;
      border: 0px;
      text-align: left;
    }
  }

  .table-row {
    display: flex;
	padding: 10px 20px;
	border-bottom: 1px solid #f4f7fb;
	align-items: center;
    .actions {
      display: block;
    }
  }
}

.color-red {
  color: #d93636;
}

.color-pink {
  color: #ea00d8;
}

.color-green {
  color: #0bd603;
}

.color-blue {
  color: #0bacdb;
}

.textUppercase {
  text-transform: uppercase;
}
.text-white {
  color: #000 !important;
}
</style>

