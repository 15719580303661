var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.extraAppClass,attrs:{"id":"app"}},[(
      !_vm.isAdmin &&
      _vm.isLoggedIn &&
      _vm.userInfo &&
      ((_vm.userInfo.whitelabel_licence !== null && !_vm.userInfo.is_first_login) ||
        _vm.userInfo.whitelabel_licence === null)
    )?_c('app-header',{attrs:{"user":_vm.userInfo,"whitelabelBrandDetail":_vm.whitelabelBrandDetail},on:{"openSearch":_vm.handleSearchOpen}}):_vm._e(),_c('router-view',{ref:"routerView",class:{ failed_user: _vm.userInfo && _vm.userInfo.billing_status == 'Failed' },attrs:{"user":_vm.userInfo,"whitelabelBrandDetail":_vm.whitelabelBrandDetail}}),_c('vue-progress-bar'),(_vm.isLoggedIn && _vm.userInfo && _vm.userActive)?_c('app-search',{ref:"appSearch"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }