var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user )?_c('div',{staticClass:"topbar tmp-topbar",attrs:{"id":"top-bar"}},[_c('div',{attrs:{"id":"toggle"},on:{"click":function($event){return _vm.select()}}},[_c('div',{staticClass:"span",class:{ active: _vm.isActiveMainMenu },attrs:{"id":"top"}}),_c('div',{staticClass:"span",class:{ active: _vm.isActiveMainMenu },attrs:{"id":"middle"}}),_c('div',{staticClass:"span",class:{ active: _vm.isActiveMainMenu },attrs:{"id":"bottom"}})]),_c('div',{class:{ active: _vm.isActiveMainMenu },attrs:{"id":"main_menu"}},[_c('el-menu',{staticClass:"el-menu-main",attrs:{"router":true,"mode":"horizontal"},on:{"select":_vm.handleSelect}},[(_vm.isMobile)?_c('div',{staticClass:"logo-img"},[_c('router-link',{attrs:{"to":"/"}},[(
              _vm.user.whitelabel_setting === null ||
              (_vm.user.whitelabel_setting !== null &&
                _vm.user.whitelabel_setting.app_logo === null)
            )?_c('img',{staticClass:"logo",attrs:{"alt":"4KPlayer","src":require("../../assets/4kplayerlogo.svg")}}):_vm._e(),(
              _vm.user.whitelabel_setting !== null &&
              _vm.user.whitelabel_setting.app_logo !== null
            )?_c('img',{staticClass:"logo",attrs:{"alt":"4KPlayer","src":_vm.user.whitelabel_setting.app_logo,"height":"40"}}):_vm._e()])],1):_vm._e(),(_vm.activeTabName !== null)?_c('div',{staticClass:"center",class:{ active: _vm.isActiveMainMenu }}):_vm._e(),(_vm.showMbsmUserProfile)?_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('div',{staticClass:"profile-detail-holder"},[_c('div',{staticClass:"user-info"},[_c('div',{staticClass:"user-name"},[_vm._v(_vm._s(_vm.user.full_name))]),_c('div',[(_vm.user.settings)?_c('img',{staticClass:"img-circle profile-avatar",attrs:{"src":_vm.user.settings.avatar,"alt":"","width":"42"}}):_vm._e()]),_c('i',{staticClass:"el-submenu__icon-arrow el-icon-arrow-down"})])]),_c('el-dropdown-menu',{staticClass:"profile-menu-items",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{staticClass:"account-detail-menu"},[_c('p',[_vm._v(_vm._s(_vm.user.full_name)),_c('br'),_vm._v(_vm._s(_vm.user.email))])]),_c('el-dropdown-item',[_c('a',{attrs:{"href":"mailto:whitelabel@bigcommand.com"}},[_vm._v("Contact Support")])]),_c('router-link',{attrs:{"to":"/logout"}},[_c('el-dropdown-item',[_c('i',{staticClass:"el-icon-switch-button"}),_vm._v("Logout")])],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"hamb"})],1),(_vm.user.billing_status == 'Failed')?_c('div',{staticClass:"failed-message"},[_c('p',[_vm._v(" Your account renewal failed. Please update the payment method on file and pay invoice "),_c('el-link',{on:{"click":function($event){return _vm.openInvoicePage(_vm.user.failed_payment.invoice)}}},[_vm._v(_vm._s(_vm.user.failed_payment.invoice.receipt_id))]),_vm._v(" to avoid service disruption on "+_vm._s(_vm.user.failed_payment.cancel_date)+". ")],1)]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }