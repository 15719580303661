<template>
  <el-container class="main-container">
    <div>
      <the-sidebar :user="user"></the-sidebar>

      <el-container class="right-side">
        <the-header v-if="plan !== null"></the-header>
        <!-- <the-sidebar></the-sidebar> -->
        <!-- <el-main> </el-main> -->
        <!-- <div class="main-content" v-if="plan !== null"> -->
          <router-view></router-view>
        <!-- </div> -->
        <!-- <div class="main-content" v-if="plan === null">
          <white-board-licence></white-board-licence>
        </div> -->
      </el-container>
    </div>
  </el-container>
</template>

<script>
import TheHeader from "../components/4kplayer/Items/TheHeader";
import TheSidebar from "../components/4kplayer/Items/TheSidebar.vue";
import WhiteBoardLicence from "../components/4kplayer/WhiteBoardLicence";
export default {
  props: [],
  data() {
    return {
      plan: null,
      user: {}
    };
  },
  components: {
    TheHeader,
    TheSidebar,
    WhiteBoardLicence,
  },

  created() {
    console.log('sjdh sdkhd', this.user);
    this.user = JSON.parse(localStorage.getItem("user_info"));
    // this.getUsers();
  },
  methods: {
    // getUsers() {
    //   let post_data = {
    //     url: "/whitelabel/plan-check",
    //   };

    //   this.$store
    //     .dispatch("post", { ...post_data })
    //     .then((response) => {
    //       if (response.data.success) {
    //         this.plan = response.data.plan;
    //         this.$router.push({ name: "WhiteBoardDashBoard" });
    //         // if (this.plan !== null && this.$route.name === "WhiteBoard") {
    //         //   this.$router.push({ name: "WhiteBoardDashBoard" });
    //         // }
    //       }
    //     })
    //     .catch((error) => {});
    // },
  },
};
</script>
<style lang="less" scoped>
.main-container {
  display: flex;
  margin-top: 30px;
}

.right-side {
  left: 260px;
  position: absolute;
  width: calc(~"100% - 260px");
  @media (max-width: 767px) {
    left: 0;
    width: 100%;
  }
  .header-container {
    position: fixed;
    width: calc(~"100% - 260px");
    z-index: 1;
    @media (max-width: 767px) {
      width: 100%;
    }
    ul {
      li {
        @media (max-width: 767px) {
          margin-left: 0;
        }
      }
    }
  }
  .main-content {
    //padding: 30px 70px;
  }
}
</style>
