<template>
  <div class="card-container">
    <h2 class="whitelable-page-heading">
      My Plans <span class="total">({{ this.plans.length }})</span>
    </h2>
    <div class="user-header">
      <div class="right-side">
        <SearchQuery
          :tip="''"
          @query="filter"
          :placeholder="'Search Plans'"
        ></SearchQuery>
        <div>
          <el-button
            type="custom"
            class="button__upload"
            round
            @click="$router.push('/whitelabel/plans/create')"
          >
            Create Plan
          </el-button>
        </div>
      </div>
    </div>
    <div class="user-list-table">
      <div class="table-header text-white">
        <div class="contact-row">Plan Name</div>
        <div class="status-row">Recurring</div>
        <div class="plan-row">Cost</div>
        <div class="joined-row">Users</div>
        <div class="last-activity-row">Revenue</div>
        <div class="asset-row">Cancelled</div>
        <div class="action-row">actions</div>
      </div>
      <div class="table-body">
        <div class="table-row" v-for="plan in plans" :key="plan.id">
          <div class="contact-row">
            <p class="mb-0 name text-white">{{ plan.plan_name }}</p>
            <p class="text-white">{{ plan.plan_id }}</p>
          </div>
          <div class="status-row color-green textCapital">
            {{ plan.recurring }}
          </div>
          <div class="plan-row text-white">${{ plan.cost }}</div>
          <div class="joined-row text-white">{{ plan.users_count }}</div>
          <div class="last-activity-row text-white">
            {{ plan.users_count * plan.cost }}
          </div>
          <div class="asset-row text-white">
            <p class="text-white">{{ plan.cancelled }}</p>
          </div>
          <div class="action-row">
            <button
              class="color-pink"
              @click="$router.push('/whitelabel/plans/edit/' + plan.id)"
            >
              Edit
            </button>
            <button
              class="color-red"
              @click="$router.push('/whitelabel/plans/move/' + plan.id)"
            >
              Move Users
            </button>
            <button class="color-red" @click="openCodeModel(plan.id)">
              Generate Code
            </button>
            <button class="color-red" @click="deletePlan(plan.id)">
              Delete
            </button>
            <!-- <button class="color-green">Delete</button> -->
          </div>
        </div>
      </div>
    </div>
    <pagination @getVideos="getPlans" :pagination="paginationData"></pagination>
    <el-dialog
      title="Add User"
      center
      class="el-dialog--huge dialog_edit dialog_border_radius add-power-player-dilogue"
      :visible.sync="showCreateUserModal"
      :modalAppendToBody="false"
    >
      <div class="relative mb-3"></div>

      <div slot="footer" class="dialog-footer">
        <el-button
          class="btn btn-primary add-power-player-button"
          :disabled="disabled"
          @click="addWhiteBoardUser"
          >Add User
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="Plan Code"
      center
      class="el-dialog--huge dialog_border_radius"
      :visible.sync="showCodeModel"
      :modalAppendToBody="false"
    >
      <div class="relative mb-3" style="width: 500px">
        <div class="generateCodeArea" style="display: flex">
          <el-input
            v-model="noOfPlans"
            type="number"
            min="0"
            step="1"
          ></el-input>

          <el-button
            style="padding: 9px 12px !important; margin-left: 15px"
            class="btn btn-primary add-power-player-button"
            @click="generateCode(active_plan_id)"
            >Generate
          </el-button>
        </div>

        <div class="availableCode">
          <div class="pendingCodes">
            <div style="padding: 5px 10px; margin: 15px">Pending Codes</div>
            <div
              v-for="code in codes"
              :key="code.id"
              class="code"
              v-if="code.used === 0 || code.used === '0'"
            >
              <span>{{ code.code }}</span>
            </div>
          </div>

          <div class="usedCodes">
            <div style="padding: 5px 10px; margin: 15px">Used Codes</div>
            <div
              v-for="code in codes"
              :key="code.id"
              class="code"
              v-if="code.used === 1 || code.used === '1'"
            >
              <span>{{ code.code }}</span>
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button
          class="btn btn-primary add-power-player-button"
          @click="exportCodes"
          >Export Pending codes
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SearchQuery from "../../components/Project/AllProjects/Components/SearchQuery";
import Pagination from "../../components/Common/Pagination";

export default {
  data() {
    return {
      totalPlan: 3,
      showCreateUserModal: false,
      disabled: false,
      plans: {},
      paginationData: {
        view: 10,
        page: 1,
        total: 0,
        start: null,
        end: null,
      },
      showCodeModel: false,
      noOfPlans: 0,
      active_plan_id: null,
      codes: null,
    };
  },
  created() {
    this.getPlans();
  },
  methods: {
    deletePlan(id) {




 var $this = this;
                this.$swal({
                    title: 'Are you sure?',
                    text: 'Deleted plans are gone forever',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    cancelButtonText: 'Cancel'
                }).then(function () {

                   

                   
             let post_data = {
        url: "/whitelabel/plan/delete/" + id,
      };

      $this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          if (response.data) {
            $this.getPlans();
          } else {
            $this.$swal({
              title: "You can not delete this plan",
              text: "Please remove user of this plan or move them to anoher plan before delete the plan.",
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Ok",
              cancelButtonText: "Cancel",
            }).then(function () {});
          }
        })
        .catch((error) => {});

                });





    
    },
    exportCodes() {
      var $this = this;
      if (this.codes.length) {
        let rows = [["CODES"]];
        this.codes.map(function (el, key) {
          const index = rows[0].length - 1;

          if (el.used === 0 || el.used === "0") {
            const data = [el.code];
            // data[index] = links;
            rows.push(data);
          }
        });

        let csvContent =
          "data:text/csv;charset=utf-8," +
          rows.map((e) => e.join(",")).join("\n");

        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "codes.csv");
        document.body.appendChild(link);

        link.click();
      }
    },
    openCodeModel(id) {
      this.showCodeModel = true;
      this.active_plan_id = id;

      let post_data = {
        url: "/whitelabel/plan/get-code/" + id,
      };

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          this.codes = response.data.codes;
        })
        .catch((error) => {});
    },
    generateCode(id) {
      let post_data = {
        url: "/whitelabel/plan/code/" + id,
        data: { noOfPlans: this.noOfPlans },
      };

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          this.noOfPlans = 0;
          this.codes = response.data.codes;
        })
        .catch((error) => {});
    },
    filter(keywords) {
      this.getPlans(keywords);
    },
    getPlans(keywords = "") {
      let post_data = {
        url: "/whitelabel/plans",
        data: {
          keywords: keywords,
          page: this.paginationData.page,
          view: this.paginationData.view,
        },
      };

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          this.plans = response.data.plans;

          this.paginationData.total = response.data.total;
          this.paginationData.start =
            (this.paginationData.page - 1) * this.paginationData.view + 1;
          this.paginationData.end = Math.min(
            this.paginationData.total,
            (this.paginationData.page - 1) * this.paginationData.view +
              this.paginationData.view
          );
        })
        .catch((error) => {});
    },
    addWhiteBoardUser() {
      console.log("add user");
    },
    indexMethod(index) {
      return index * 2;
    },
  },
  components: {
    SearchQuery,
    Pagination,
  },
};
</script>

<style lang="less">
.availableCode {
  display: flex;
  max-height: 400px;
  height: 200px;
  overflow: auto;
  .code {
    padding: 5px 10px;
    background: #bbecff87;
    margin: 15px;
    border-radius: 5px;
  }
  .pendingCodes,
  .usedCodes {
    flex-wrap: wrap;
    flex: 0 0 50%;
  }
}
.toolbar__search {
  /*margin-right: 31px;*/

  .search__icon {
    box-sizing: border-box;
  }

  .filter__search {
    input.el-input__inner {
      height: 32px;
      font-size: 13px;
    }

    &:focus-within {
    }
  }
}

.filter__search {
  display: flex;
  align-items: center;
  border: 1px solid #0a0a0a;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;

  .el-input-group__prepend {
    margin-right: 0 !important;
    display: block;
    width: auto;
    background-color: transparent;
    line-height: 0;
    border: 0;
    padding-right: 8px;
    padding-left: 8px;

    svg {
      width: 16px;
    }
  }

  input.el-input__inner {
    box-sizing: border-box;
    width: 0;
    height: 32px;
    padding-left: 0;
    padding-right: 0;
    font-size: 13px;
    border: 0;
    transition: all 0.3s ease-out;
  }

  svg {
    path {
      fill: #0a0a0a;
    }
  }

  &:focus-within,
  &:hover {
    border-color: #0a0a0a;

    .el-input-group__prepend {
      svg {
        path {
          fill: #0a0a0a;
        }
      }
    }

    input.el-input__inner {
      width: 180px;
      padding-right: 10px;
    }

    @media (max-width: 960px) {
      input.el-input__inner {
        width: 100px;
      }
    }
  }
}

.textCapital {
  text-transform: capitalize;
}
.users-main {
  padding: 40px;
  top: 55px;
  position: absolute;
  width: 100%;
  overflow: auto;
}
.user-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .left-side {
    .title {
      font-weight: bold;
      font-size: 22px;
    }
    .total {
      margin-left: 15px;
      font-weight: normal;
    }
  }
  .el-button--custom {
    font-weight: bolder;
  }
  .right-side {
    display: flex;
    align-items: center;
    .text-uppercase {
      font-weight: 700;
    }
    /*div {*/
    /*  margin-right: 20px;*/
    /*}*/
    .button__upload {
      padding: 10px 25px 9px;
      background-color: #0044b1;
      border: none;
      font-size: 16px;
      line-height: 19px;
      color: white;
      border-radius: 5px;
    }
  }
}
.user-list-table {
  min-width: 900px;
  p {
    margin-bottom: 5px;
  }
  .table-header {
    display: flex;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  .contact-row {
    width: 19%;
    p.name {
      font-weight: bold;
    }
    p {
      margin-bottom: 5px;
      color: #2c3e50;
    }
  }
  .status-row {
    width: 10%;
  }
  .plan-row {
    width: 10%;
  }
  .joined-row {
    width: 10%;
  }
  .last-activity-row {
    width: 17%;
  }
  .asset-row {
    width: 17%;
  }
  .table-body {
    .plan-row,
    .joined-row,
    .last-activity-row,
    .asset-row {
      color: #485050cf;
    }
  }
  .action-row {
    width: 17%;
    display: inline-grid;
    button {
      background: none;
      border: 0px;
      text-align: left;
    }
  }
  .table-row {
    display: flex;
    padding-bottom: 30px;
    .actions {
      display: block;
    }
  }
}
.color-red {
  color: #d93636;
}
.color-pink {
  color: #ea00d8;
}
.color-green {
  color: #0bd603;
}
.color-blue {
  color: #0bacdb;
}
.text-white {
  color: var(--textThemeColor);
}
</style>
