var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popover-message",style:({
    "width": _vm.width ? _vm.width : "inherit",
    "height": _vm.height ? _vm.height : "inherit",
    "top": _vm.top ? _vm.top : "inherit",
    "bottom": _vm.bottom ? _vm.bottom : "inherit",
    "right": _vm.right ? _vm.right : "inherit",
    "left": _vm.left ? _vm.left : "inherit"
})},[_vm._v(" "+_vm._s(_vm.message)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }