<template>
    <div class="card-container">
        <h2 class="whitelable-page-heading"> My Users <span class="total">({{ this.users.length }})</span>
                </h2>
        <div class="user-header">
                
            <div class="right-side">
                
              <SearchQuery :tip="''" @query='filter' :placeholder="'Search Users'"></SearchQuery>
                <div>
                    <el-button
                            type="custom"
                            class="button__upload btn-default"
                            round
                            @click="exportUser"
                            
                    >
                    Export
                    </el-button>
                    <el-button
                            type="custom"
                            class="button__upload"
                            round
                            @click="$router.push('/whitelabel/users/create')"
                    >


                        Create User
                    </el-button>
                </div>
            </div>
        </div>
        <div class="user-list-table">
            <div class="table-header text-white">
                <div class="contact-row">conatact</div>
                <div class="status-row">status</div>
                <div class="plan-row">plan</div>
                <div class="joined-row">joined</div>
                <div class="last-activity-row">last activity</div>
                <div class="asset-row">asset</div>
                <div class="action-row">action</div>
            </div>
            <div class="table-body text-white">
                <div class="table-row" v-for="data in users" :key="data.id">
                    <div class="contact-row">
                        <p class="mb-0 name text-white">{{ data.name }}</p>
                        <p class="text-white">{{ data.email }}</p>
                    </div>
                    <div class="status-row text-white" :class="{'color-red' : data.whitelabel_status === 'ban', 'color-green' : data.whitelabel_status == null}">{{data.whitelabel_status == null ? 'Active' : data.whitelabel_status }}</div>
                    <div class="plan-row text-white">{{data.whitelabel_plan.plan_name}}</div>
                    <div class="joined-row text-white">{{ changeDateFormate(data.created_at) }}</div>
                    <div class="last-activity-row text-white">{{ changeDateFormate(data.updated_at) }}</div>
                    <div class="asset-row text-white">
                        <p class="text-white">Videos: {{data.total_videos}}</p>
                        <p class="text-white">Contacts: {{data.child_users_count}}</p>
                        <p class="text-white">Impressions: {{data.total_impression}}</p>
                        <p class="text-white">Views: {{data.total_video_view}}</p>
                        <p class="text-white">Users: {{data.child_users_count}}</p>
                    </div>
                    <div class="action-row">
                        <button class="color-pink" @click="banUser(data.id)" v-if="data.whitelabel_status === null">Ban</button>
                        <button class="color-pink" @click="activeUser(data.id)" v-if="data.whitelabel_status === 'ban'">Active</button>
                        <button class="color-red" @click="deleteUser(data.id)">Delete</button>
                        <button class="color-green" @click="signInUser(data.id)">Signin</button>
                        <button class="color-blue" @click="redirectUser(data.id)">Reset pass</button>
                    </div>
                </div>
            </div>
        </div>
      <pagination @getVideos="getUsers" :pagination="paginationData"></pagination>

    </div>
</template>


<script>
    import SearchQuery from "../../components/Project/AllProjects/Components/SearchQuery";
    import Pagination from '../../components/Common/Pagination';

    export default {
        components: {SearchQuery, Pagination},
        data() {
            return {
                users: {},
                paginationData: {
                    view: 10,
                    page: 1,
                    total: 0,
                    start: null,
                    end: null
                },
            };
        },
        computed: {},
      created(){
        this.getUsers();
      },
        methods: {
            signInUser(id){
let post_data = {
              url: "/whitelabel/user/signIn/" + id,

            };


            this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        if(response.data.id !== null){

                            var url = process.env.VUE_APP_4k_URL;
                            if(response.data.instance !== null){

                                 var url = 'https://' + response.data.instance+'/';

                            }
                            window.open(url + 'login/2?login_id=' + response.data.id, '_blank');
                           
                        }else{

                            var url = process.env.VUE_APP_4k_URL;
                            if(response.data.instance !== null){

                                 var url = 'https://' + response.data.instance+'/';

                            }

                            window.open(url + 'login/2');
                        }
                     

                     

                    })
                    .catch(error => {

                    });
            },
            exportUser(){
                var $this = this;
                if (this.users.length) {
                    let rows = [
                        [
                            "First Name", "Status", "Plan", "Last Activity"
                        ]
                    ];
                    this.users.map(function (el, key) {
                       const index = rows[0].length - 1;
                       var date = $this.changeDateFormate(el.created_at);
                        const data = [
                            el.name ?? '', el.whitelabel_status, el.whitelabel_plan.plan_name, date 
                        ];
                        // data[index] = links;
                        rows.push(data);
                    });

                    let csvContent =
                        "data:text/csv;charset=utf-8," +
                        rows.map(e => e.join(",")).join("\n");

                    let encodedUri = encodeURI(csvContent);
                    let link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "Contacts.csv");
                    document.body.appendChild(link);

                    link.click();
                }
            },
            redirectUser(id){
                this.$router.push({ name: 'WhiteBoardResetPass', params: { id: id } })
            },
          banUser(id){
            let post_data = {
              url: "/whitelabel/user/ban/" + id,

            };


            this.$store.dispatch("post", {...post_data})
                    .then(response => {
                      this.getUsers();
                      this.$message({
                            showClose: true,
                            message: "User has been baned.",
                            type: "success"
                       });

                    })
                    .catch(error => {

                    });
          },
          activeUser(id){
   let post_data = {
              url: "/whitelabel/user/active/" + id,

            };


            this.$store.dispatch("post", {...post_data})
                    .then(response => {
                      this.getUsers();
                      this.$message({
                            showClose: true,
                            message: "User has been activated.",
                            type: "success"
                       });

                    })
                    .catch(error => {

                    });
          },
          deleteUser(id){



                var $this = this;
                this.$swal({
                    title: 'Are you sure?',
                    text: 'Deleted users are gone forever',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    cancelButtonText: 'Cancel'
                }).then(function () {

                   

                   
            let post_data = {
              url: "/whitelabel/user/delete/" + id,

            };


            $this.$store.dispatch("post", {...post_data})
                    .then(response => {
                    $this.getUsers()

                    })
                    .catch(error => {

                    });

                });



          },
          filter(keywords) {
            this.getUsers(keywords)
          },
          changeDateFormate(date){
            var myArr = date.split(" ");
            var data = myArr[0].split("-");


            var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            var d = new Date(myArr[0]);
            var monthName=months[d.getMonth()];

            return monthName+ ' '+ data[2] + ', '+ data[0];

          },
            getUsers(keywords = '') {
                let post_data = {
                    url: "/whitelabel/user/list",
                    data: {
                        keywords: keywords,
                        page: this.paginationData.page,
                        view: this.paginationData.view
                    },
                };


                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        this.users = response.data.users;


                        this.paginationData.total = response.data.total;
                        this.paginationData.start = (this.paginationData.page - 1) * (this.paginationData.view) + 1
                        this.paginationData.end = Math.min(this.paginationData.total, (this.paginationData.page - 1) * (this.paginationData.view) + this.paginationData.view)

                    })
                    .catch(error => {

                    });
            }

        },

    };
</script>

<style lang="less">
    .users-main {
        padding: 40px;
        overflow: auto;
        top: 55px;
        position: absolute;
        width: 100%;
    }

    .user-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .left-side {
            .title {
                font-weight: bold;
                font-size: 22px;
            }

            .total {
                margin-left: 15px;
                font-weight: normal;
            }
        }

        .el-button--custom {
            font-weight: bolder;
        }

        .right-side {
            display: flex;
            align-items: center;

            .text-uppercase {
                font-weight: 700;
            }

            div {
                margin-right: 20px;
            }

            .button__upload {
                padding: 10px 25px 9px;
                background-color: var(--buttonThemeColor);
                border: none;
                font-size: 16px;
                line-height: 19px;
                color: white;
                border-radius: 5px;
            }
        }
    }

    .user-list-table {
        min-width: 900px;

        p {
            margin-bottom: 5px;
        }

        .table-header {
            display: flex;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .contact-row {
            width: 19%;

            p.name {
                font-weight: bold;
            }

            p {
                margin-bottom: 5px;
                color: #2c3e50;
            }
        }

        .status-row {
            width: 10%
        }

        .plan-row {
            width: 10%;
        }

        .joined-row {
            width: 10%;
        }

        .last-activity-row {
            width: 17%;
        }

        .asset-row {
            width: 17%;
        }

        .table-body {
            .plan-row, .joined-row, .last-activity-row, .asset-row {
                color: #485050cf;
            }
        }

        .action-row {
            width: 17%;
            display: inline-grid;

            button {
                background: none;
                border: 0px;
                text-align: left;
            }
        }

        .table-row {
            display: flex;
            padding-bottom: 30px;

            .actions {
                display: block;
            }
        }
    }

    .color-red {
        color: #d93636;
    }

    .color-pink {
        color: #ea00d8;
    }

    .color-green {
        color: #0bd603;
    }

    .color-blue {
        color: #0bacdb;
    }
</style>

