<template>
    <div class='pagination d-flex justify-content-between'>
        <div class='pagination-left'>
            <div class='pagination-left-title'>View</div>
            <div class='pagination-left-count'>
                <select @change='paginateViewasas($event)'>
                    <option  v-for='(item, index) in paginationOption' :key='index'>{{ item }}</option>
                </select>
            </div>
        </div>
        <div class='pagination-right d-flex justify-content-between'>
           
                <div  class='pagination-right-prev btn' @click='paginatePrev'  :class='this.pagination.start <= 1 ? "disabled" : ""' :disabled="this.pagination.start <= 1">
                    <ArrowRight></ArrowRight>
                </div>
            <div class='pagination-right-count'>
                {{this.pagination.start}} - {{this.pagination.end}} of {{this.pagination.total}}
            </div>
          
             <div  class='pagination-right-next btn' @click='paginateNext' :class='this.pagination.end >= this.pagination.total ? "disabled" : ""'>
                    <ArrowRight></ArrowRight>
                </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .pagination {
        padding: 15px 0px;
        .pagination-left {
            width: 123px;
            height: 31px;
            display: flex;
            flex-direction: row;
            border: 1px solid #C7E9F2;
            justify-content: space-between;
            border-radius: 20px;
            .pagination-left-title {
                color: #ccc;
                padding: 5px 15px;
                border-right: 1px solid #C7E9F2;
            }
            .pagination-left-count {
                color: #ccc;
                padding: 5px 15px 5px 0px;
               select {
                    background: transparent !important;
                    border: transparent !important;
                    outline: none !important;
                    cursor: pointer;
                }
            }
        }
        .pagination-right {
            width: 157px;
            height: 31px;
            border-radius: 20px;
            border: 1px solid #C7E9F2;
            padding: 0px 15px;
            .pagination-right-count {
                padding: 5px 10px;
                border-left: 1px solid #C7E9F2;
                border-right: 1px solid #C7E9F2;
            }
            .pagination-right-next,
            .pagination-right-prev {
                padding: 5px 0px !important;
                cursor: pointer;
            }
            .pagination-right-prev svg {
                transform: rotate(180deg);
            }
        }
        .disabled {
            opacity: 0.3;
        }
    }
</style>

<script>

    import ArrowRight from '../../components/Admin/SvgComponents/ArrowRightIcon';


export default {
    name: 'Pagination',
    props: ['pagination'],
    components:{
        ArrowRight
    },
    data() {
        return {
            paginationOption: [10, 25, 50, 100],
        }
    },
    methods: {
        paginateNext(){
            this.pagination.page = (this.pagination.page + 1)

            this.$emit('getVideos');
        },
        paginatePrev(){
            this.pagination.page = (this.pagination.page - 1)

            this.$emit('getVideos');
        },
        paginateViewasas(event){
            this.pagination.view = Number(event.target.value);
            this.pagination.page = 1

            this.$emit('getVideos');
        }

    },

}
</script>
