import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import store from "../store/store";
import BCookie from "./../utils/BCookie";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: routes,
});

/**
 * Authentication check and return to login page before route change.
 */
router.beforeEach((to, from, next) => {

	console.log('Current route', to);

  const authUser = JSON.parse(localStorage.getItem('user_info'));
  console.log('Current route', authUser);
  const urlParams = new URLSearchParams(window.location.search);
  const myref = urlParams.get("ref");
  const mylid = urlParams.get("lid");
  if (myref !== null && mylid !== null) {
    var exdate = new Date();
    var exdays = 7;
    exdate.setDate(exdate.getDate() + exdays);
    
    var c_value = exdays == null ? "" : "; expires=" + exdate.toUTCString();
    
    document.cookie = "ref=" + myref;
    document.cookie = "lid=" + mylid;
  }
  if (!store.getters.isAuthenticated) {
	
    if (to.meta.auth) {
      BCookie.set("redirect_path", window.location.pathname, 24 * 60 * 60);
      next({
        name: "Login1",
        params: { step: 1 },
      });
    } else {
      next();
    }
  } else {

	if(typeof to.meta.whitelabel !== 'undefined'){

			if((to.path === "/" || to.name === 'WhiteBoard') &&  authUser.whitelabel_setting && authUser.whitelabel_setting.owner){

        if(authUser.whitelabel_licence && authUser.is_first_login){
                        
          window.location.replace("/whitelabel/beginning/info");
         
                             }else{
                              next({name: "WhiteBoardDashBoard"});
                             }

			
			}else if((to.meta.whitelabel && (authUser.whitelabel_setting && !authUser.whitelabel_setting.owner)) || (!to.meta.whitelabel &&  authUser.whitelabel_setting && authUser.whitelabel_setting.owner)){
				console.log('Whitelabel auth issue logout 1');
				store.dispatch("destroyToken").then(response => {
					window.location.replace("/login/1");
				});
				next({
				  name: "Login1",
				  params: { step: 1 },
				});
			}else{
				console.log(process.env.VUE_APP_WHITELABEL_DOMAIN, location.host, '????');
				if(to.meta.whitelabel && process.env.VUE_APP_WHITELABEL_DOMAIN !== location.host ){
					console.log('Whitelabel auth issue logout 2');
					store.dispatch("destroyToken").then(response => {
							window.location.replace("/login/1");
						});
					next({
					name: "Login1",
					params: { step: 1 },
					});
				}
				
			}
	}

    next();
  }
});

//====change page title after route changed.
router.afterEach((to, from) => {
  if (to.meta.title) {
    document.title = to.meta.title + " - " + store.state.siteName;
    store.commit("changePageTitle", to.meta.title);
  }
});

export default router;
