import Vue from "vue";

const state = {
  video_Publish: {
    stream_start_date: null,
    stream_start_hour: "",
    stream_start_min: "",
    stream_end_date: null,
    stream_end_hour: "",
    stream_end_min: "",
    is_schedule: false,
    is_stream_start_text: false,
    is_end_stream: false,
    is_stream_end_text: false,
    is_action_button: false,
    button_link: "",
  },
  publish_condition: {
    is_schedule_running: false,
    is_stream_ended: false,
    is_schedule: false,
  },
};

const getters = {};

const mutations = {
  updateDate: (state, value) => {
    if (value.type == "start") {
      state.video_Publish.stream_start_date = value.value;
      console.log(state.video_Publish.stream_start_date);
      console.log(value.value);
    } else {
      state.video_Publish.stream_end_date = value.value;
    }
    console.log(state);
  },
  updateHour: (state, value) => {
    console.log(value);

    if (value.type == "start") {
      state.video_Publish.stream_start_hour = value.value;
    } else {
      state.video_Publish.stream_end_hour = value.value;
    }
  },
  updateMin: (state, value) => {
    console.log(value);
    if (value.type == "start") {
      state.video_Publish.stream_start_min = value.value;
    } else {
      state.video_Publish.stream_end_min = value.value;
    }
  },
  setPublishVideo: (state, value) => {
    if (value.hasOwnProperty("is_schedule")) {
      value.is_schedule = value.is_schedule == "1" ? true : false;
      value.is_end_stream = value.is_end_stream == "1" ? true : false;
      value.is_action_button = value.is_action_button == "1" ? true : false;
      value.is_stream_end_text = value.is_stream_end_text == "1" ? true : false;
      value.is_stream_start_text =
        value.is_stream_start_text == "1" ? true : false;
      state.video_Publish = value;
    }
  },
  updatePublishStatus(state, value) {
    if (value.type == "is_schedule") {
      state.publish_condition.is_schedule = value.value;
    }
    if (value.type == "is_running") {
      state.publish_condition.is_schedule_running = value.value;
    }

    if (value.type == "is_stream_ended") {
      state.publish_condition.is_stream_ended = value.value;
    }
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
