import { render, staticRenderFns } from "./WhiteBoardDashBoard.vue?vue&type=template&id=944dd484&scoped=true&"
import script from "./WhiteBoardDashBoard.vue?vue&type=script&lang=js&"
export * from "./WhiteBoardDashBoard.vue?vue&type=script&lang=js&"
import style0 from "./WhiteBoardDashBoard.vue?vue&type=style&index=0&id=944dd484&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "944dd484",
  null
  
)

export default component.exports