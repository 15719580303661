<template>
  <div class="card-container">
     <h2 class="whitelable-page-heading"> New Plan</h2>

    <div class="relative mb-3">
      <el-row :gutter="40" class="m-top-3">
        <el-col :span="24" :xs="24">
          <div class="branding-logo-position">
            <div class="positions title">
              <label class=" key-label">Plan Name</label>
            </div>
            <div class="positions">
              <input
                class="form-control with_arrow_190"
                id="user-first-name"
                placeholder="Enter plan name"
                type="text"
                v-model="plan_name"
              />
            </div>
            <span class="error" v-if="validation.type === 'plan_name'">
              <img
                alt
                class="warning-icon"
                src="../../assets/img/auth/warning.svg"
              />
              {{ validation.message }}</span
            >
          </div>

          <div class="branding-logo-position">
            <div class="positions title">
              <label class=" key-label">Plan Type</label>
            </div>
            <div class="positions">
              <el-row>
                <!-- <el-col :span="1">&nbsp;</el-col> -->
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="1"
                    class="text-white"
                    v-model="plan_type"
                    :label="'free'"
                    @click="plan_type = 'free'"
                    >Free
                  </el-radio>
                </el-col>
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="2"
                    class="text-white"
                    v-model="plan_type"
                    :label="'paid'"
                    @click="plan_type = 'paid'"
                    >Paid
                  </el-radio>
                </el-col>
                
              </el-row>

              <!-- <el-row class="m-top-2">
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="10" :xs="23">
                  <el-radio
                    label="2"
                    class="text-white"
                    v-model="plan_type"
                    :label="'paid'"
                    @click="plan_type = 'paid'"
                    >Paid
                  </el-radio>
                </el-col>
              </el-row> -->
            </div>
          </div>
          <div class="branding-logo-position" v-if="plan_type !== 'free'">
            <div class="positions title">
              <label class=" key-label">Recurring</label>
            </div>
            <div class="positions">
              <el-row>
                <!-- <el-col :span="1">&nbsp;</el-col> -->
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="1"
                    v-model="recurring"
                    class="text-white"
                    :label="'never'"
                    @click="recurring = 'never'"
                  >
                    Never
                  </el-radio>
                </el-col>
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="2"
                    v-model="recurring"
                    class="text-white"
                    :label="'monthly'"
                    @click="recurring = 'monthly'"
                  >
                    Monthly
                  </el-radio>
                </el-col>
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="2"
                    v-model="recurring"
                    class="text-white"
                    :label="'annually'"
                    @click="recurring = 'annually'"
                  >
                    Annually
                  </el-radio>
                </el-col>
              </el-row>

             
            </div>
          </div>
          <div class="branding-logo-position" v-if="plan_type !== 'free'">
            <div class="positions title">
              <label class=" key-label">Free Trial</label>
            </div>
            <div class="positions">
              <el-row>
                <!-- <el-col :span="1">&nbsp;</el-col> -->
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="1"
                    v-model="free_trial"
                    class="text-white"
                    :label="'none'"
                    @click="free_trial = 'none'"
                    >None
                  </el-radio>
                </el-col>
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="2"
                    v-model="free_trial"
                    class="text-white"
                    :label="'7_days_free'"
                    @click="free_trial = '7_days_free'"
                    >7 Days Free
                  </el-radio>
                </el-col>
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="2"
                    v-model="free_trial"
                    class="text-white"
                    :label="'14_days_free'"
                    @click="free_trial = '14_days_free'"
                    >14 Days Free
                  </el-radio>
                </el-col>
                <el-col :span="5" :xs="24">
                  <el-radio
                    label="2"
                    v-model="free_trial"
                    class="text-white"
                    :label="'30_days_free'"
                    @click="free_trial = '30_days_free'"
                    >30 Days Free
                  </el-radio>
                </el-col>
              </el-row>
            </div>
          </div>
           <el-row>
                
              <el-col :span="12" :xs="24">
                <div class="branding-logo-position" v-if="plan_type !== 'free'">
                  <div class="positions title">
                    <label class=" key-label">FEE (US $)</label>
                  </div>
                  <div class="positions">
                    <input
                      class="form-control with_arrow_190"
                      id="user-last-name"
                      placeholder="Enter fees"
                      type="text"
                      v-model="fees"
                    />
                  </div>
                  <span class="error" v-if="validation.type === 'fees'">
                    <img
                      alt
                      class="warning-icon"
                      src="../../assets/img/auth/warning.svg"
                    />
                    {{ validation.message }}</span
                  >
                </div>
              </el-col>
              <el-col :span="12" :xs="24">
                <div class="branding-logo-position">
                  <div class="positions title">
                    <label class=" key-label">Video count</label>
                  </div>
                  <div class="positions mr-15">
                    <input
                      class="form-control with_arrow_190"
                      id="user-last-name"
                      placeholder="0"
                      type="number"
                      min="0"
                      v-model="video_count"
                    />
                  </div>
                </div>
              </el-col>
			<el-col :span="24" :xs="24">
				<div class="branding-logo-position">
					<div class="positions title">
					<label class=" key-label">Video source</label>
					</div>
					<div class="positions text-white">
					<el-checkbox-group v-model="videoSource">
						<el-checkbox label="Vimeo" class="text-white"></el-checkbox>
						<el-checkbox label="Youtube" class="text-white"></el-checkbox>
						<el-checkbox label="Wistia" class="text-white"></el-checkbox>
						<el-checkbox label="MP4" class="text-white"></el-checkbox>
						<el-checkbox label="M3U8" class="text-white"></el-checkbox>
						<el-checkbox label="Live" class="text-white"></el-checkbox>
					</el-checkbox-group>
					</div>
				</div>
			</el-col>
			<el-col :span="24" :xs="24">
				<div class="branding-logo-position">
					<div class="positions title">
					<label class=" key-label">Player Skin</label>
					</div>
					<div class="positions text-white">
					<el-checkbox-group v-model="playerSkin">
						<el-checkbox label="AERO" class="text-white"></el-checkbox>
						<el-checkbox label="BOLD" class="text-white"></el-checkbox>
						<el-checkbox label="CLEAN" class="text-white"></el-checkbox>
						<el-checkbox label="MODERN" class="text-white"></el-checkbox>
					</el-checkbox-group>
					</div>
				</div>
			</el-col>
			<el-col :span="8" :xs="12">
				<div class="branding-logo-position">
					<div class="positions title">
					<label class=" key-label">Interaction Tools</label>
					</div>
					<div class="positions text-white">
					<el-switch
						v-model="interaction_tools"
						active-color="#13ce66"
						inactive-color="#ff4949"
					>
					</el-switch>
					</div>
				</div>
			</el-col>
			<el-col :span="8" :xs="12">
				<div class="branding-logo-position">
					<div class="positions title">
						<label class=" key-label">Video Behaviors</label>
					</div>
					<div class="positions text-white">
					<el-switch
						v-model="video_behaviours"
						active-color="#13ce66"
						inactive-color="#ff4949"
					>
					</el-switch>
					</div>
				</div>
			</el-col>
			<el-col :span="8" :xs="12">
				<div class="branding-logo-position">
					<div class="positions title">
					<label class="key-label"
						>Dynamic Video Funnels</label
					>
					</div>
					<div class="positions text-white">
					<el-switch
						v-model="dynamic_video_funnel"
						active-color="#13ce66"
						inactive-color="#ff4949"
					>
					</el-switch>
					</div>
				</div>
		  	</el-col>
			  <el-col :span="24" :xs="24" style="margin-top:25px">
				  <span class="textUppercase text-white">Add User Webhook</span>
			  </el-col>
			  <el-col :span="6" :xs="24">
				  
				<div class="branding-logo-position">
					<div class="positions title">
						<label class=" key-label">Method</label>
					</div>
					<div class="positions text-white">
					
						<el-select
						v-model="add_user_webhook_method"
						placeholder="Select"
						>
						<el-option label="GET" value="GET">GET</el-option>
						<el-option label="POST" value="POST">POST</el-option>
						</el-select>
					
					</div>
				</div>
  			</el-col>
			<el-col :span="12" :xs="24">
				<div class="branding-logo-position">
					<div class="positions title">
						<label class=" key-label">Url</label>
					</div>
						<div class="positions text-white">
							<input
							class="expand field"
							id="user-first-name"
							placeholder="Enter webhook url"
							type="text"
							v-model="add_user_webhook"
							/>
							<span
							class="error"
							v-if="validation.type === 'add_user_webhook'"
							>
							<img
								alt
								class="warning-icon"
								src="../../assets/img/auth/warning.svg"
							/>
							{{ validation.message }}</span
							>
						
						</div>
				</div>
	  		</el-col>
			
			<el-col :span="6" :xs="24">
          		<div class="branding-logo-position" v-if="add_user_webhook_method == 'POST'">
					<div class="positions title">
						<label class=" key-label">Payload</label>
					</div>
            		<div class="positions text-white">
						<div>
							<textarea
								class="expand field"
								id="user-first-name"
								placeholder="Enter first name"
								type="text"
								v-model="add_user_webhook_payload"
							/>
							<span
							class="error"
							v-if="validation.type === 'add_user_webhook_payload'"
							>
							<img
								alt
								class="warning-icon"
								src="../../assets/img/auth/warning.svg"
							/>
							{{ validation.message }}</span
							>
						</div>
					</div>
				</div>
			</el-col>
			  <el-col :span="24" :xs="24" style="margin-top:25px">
				  <span class="textUppercase text-white">Remove User Webhook</span>
			  </el-col>
			  <el-col :span="6" :xs="24">
				<div class="branding-logo-position">
					<div class="positions title">
					<label class=" key-label">Method</label>
					</div>
					<div class="positions text-white">
					<div>
						<el-select
						v-model="remove_user_webhook_method"
						placeholder="Select"
						>
						<el-option label="GET" value="GET">GET</el-option>
						<el-option label="POST" value="POST">POST</el-option>
						</el-select>
					</div>
					</div>
          		</div>
  			</el-col>
			<el-col :span="12" :xs="24">
				<div class="branding-logo-position">
					<div class="positions title">
						<label class=" key-label">Url</label>
					</div>
					<div class="positions text-white">
						<input
							class="expand field"
							id="user-first-name"
							placeholder="Enter webhook url"
							type="text"
							v-model="remove_user_webhook"
						/>
						<span
							class="error"
							v-if="validation.type === 'remove_user_webhook'"
						>
							<img
							alt
							class="warning-icon"
							src="../../assets/img/auth/warning.svg"
							/>
							{{ validation.message }}</span
						>
					</div>
				</div>
			</el-col>
			
			<el-col :span="6" :xs="24">
				<div class="branding-logo-position" v-if="remove_user_webhook_method == 'POST'">
					<div class="positions title">
						<label class=" key-label">Payload</label>
					</div>
					<div class="positions text-white">
						<div>
							<textarea
							class="expand field"
							id="user-first-name"
							placeholder="Enter first name"
							type="text"
							v-model="remove_user_webhook_payload"
							/>
							<span
							class="error"
							v-if="validation.type === 'remove_user_webhook_payload'"
							>
							<img
								alt
								class="warning-icon"
								src="../../assets/img/auth/warning.svg"
							/>
							{{ validation.message }}</span
							>
						</div>
					</div>
				</div>
		  	</el-col>
        <el-col :span="24" :xs="24">
				<div class="branding-logo-position">
				
					<div class="positions">
             <el-button
              type="custom"
              class="button__cancle"
              @click="$router.push('/whitelabel/plans')"
              >Cancel
            </el-button>
            <el-button type="custom" class="button__upload" @click="savePlan()">
              Create Plan
            </el-button>
					</div>
				</div>
		  	</el-col>
		  </el-row>
        </el-col>
      </el-row>
    </div>
   
  </div>
</template>


<script>
import SearchQuery from "../../components/Project/AllProjects/Components/SearchQuery";
import AuthEmailField from "../Auth/Items/AuthEmailField.vue";
import PasswordChecker from "./PasswordChecker.vue";

export default {
  name: "WhiteBoardCreatePlans",
  components: {},
  data() {
    return {
      plan_name: "",
      fees: "0",
      plan_type: "paid",
      recurring: "monthly",
      free_trial: "7_days_free",
      video_count: 0,
      videoSource: [],
      playerSkin: [],
      interaction_tools: true,
      video_behaviours: true,
      dynamic_video_funnel: true,
      validation: {
        message: "",
        type: "",
      },

      add_user_webhook: "",
      add_user_webhook_method: "post",
      add_user_webhook_payload: null,

      remove_user_webhook: null,
      remove_user_webhook_method: "post",
      remove_user_webhook_payload: null,
    };
  },
  computed: {},
  methods: {
    validURL(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },
    isValidJson(json) {
      try {
        JSON.parse(json);
        return true;
      } catch (e) {
        return false;
      }
    },
    savePlan() {
      if (this.plan_name === "") {
        this.validation.message = "Please enter plan name";
        this.validation.type = "plan_name";

        return false;
      } else if ((this.fees === "0" || this.fees === 0) && this.plan_type !== 'free') {
        this.validation.message = "Please enter fees";
        this.validation.type = "fees";

        return false;
      } 
      // else if (
      //   this.add_user_webhook === "" ||
      //   this.add_user_webhook === null
      // ) {
      //   this.validation.message = "Please enter url";
      //   this.validation.type = "add_user_webhook";

      //   return false;
      // } 
      else if (
        this.add_user_webhook !== "" &&
        this.add_user_webhook !== null
      ) {
        var valid = this.validURL(this.add_user_webhook);

        if (!valid) {
          this.validation.message = "Please enter valid url";
          this.validation.type = "add_user_webhook";

          return false;
        }
      } 
      // else if (
      //   this.add_user_webhook_method === "post" &&
      //   (this.add_user_webhook_payload === null ||
      //     this.add_user_webhook_payload === "")
      // ) {
      //   this.validation.message = "Please enter payload";
      //   this.validation.type = "add_user_webhook_payload";

      //   return false;
      // }
       else if (
        this.add_user_webhook_payload !== "" &&
        this.add_user_webhook_payload !== null
      ) {
        var valid = this.validURL(this.add_user_webhook_payload);
        if (!valid) {
          this.validation.message = "Please enter valid payload";
          this.validation.type = "add_user_webhook_payload";

          return false;
        }
      } 
      // else if (
      //   this.remove_user_webhook === "" ||
      //   this.remove_user_webhook === null
      // ) {
      //   this.validation.message = "Please enter url";
      //   this.validation.type = "remove_user_webhook";

      //   return false;
      // }
       else if (
        this.remove_user_webhook !== "" &&
        this.remove_user_webhook !== null
      ) {
        var valid = this.validURL(this.remove_user_webhook);

        if (!valid) {
          this.validation.message = "Please enter valid url";
          this.validation.type = "remove_user_webhook";

          return false;
        }
      }
      //  else if (
      //   this.remove_user_webhook_method === "post" &&
      //   (this.remove_user_webhook_payload === null ||
      //     this.remove_user_webhook_payload === "")
      // ) {
      //   this.validation.message = "Please enter payload";
      //   this.validation.type = "remove_user_webhook_payload";

      //   return false;
      // } 
      else if (
        this.remove_user_webhook_payload !== "" &&
        this.remove_user_webhook_payload !== null
      ) {
        var valid = this.validURL(this.remove_user_webhook_payload);
        if (!valid) {
          this.validation.message = "Please enter valid payload";
          this.validation.type = "remove_user_webhook_payload";

          return false;
        }
      } else {
        this.validation.message = "";
        this.validation.type = "";
      }
      var $this = this;
      let post_data = {
        url: "/whitelabel/plan/store",
        data: {
          plan_name: this.plan_name,
          fees: this.fees,
          plan_type: this.plan_type,
          recurring: this.recurring,
          free_trial: this.free_trial,
          video_count: this.video_count,
          videoSource: this.videoSource,
          playerSkin: this.playerSkin,
          interaction_tools: this.interaction_tools,
          video_behaviours: this.video_behaviours,
          dynamic_video_funnel: this.dynamic_video_funnel,

          add_user_webhook: this.add_user_webhook,
          add_user_webhook_method: this.add_user_webhook_method,
          add_user_webhook_payload: this.add_user_webhook_payload,
          remove_user_webhook: this.remove_user_webhook,
          remove_user_webhook_method: this.remove_user_webhook_method,
          remove_user_webhook_payload: this.remove_user_webhook_payload,
        },
      };

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          if (response.data.result === "success") {
            // $router.push('/white-label/users')
            this.$router.push({ name: "WhiteBoardPlans" });
          }
          if (response.data.result === "error") {
            $this.$message({
              showClose: true,
              message: response.data.msg,
              type: "success",
            });
          }
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="less">
.error {
  color: red;
}
.button__cancle {
  padding: 10px 25px 9px;
  background-color: #fff;
  border: 1px solid var(--buttonThemeColor);
  font-size: 16px;
  line-height: 19px;
  color: var(--buttonThemeColor);
  border-radius: 5px;
}
.button__upload {
  padding: 10px 25px 9px;
  background-color: var(--buttonThemeColor);
  border: none;
  font-size: 16px;
  line-height: 19px;
  color: white;
  border-radius: 5px;
}
.users-main {
  padding: 40px;
  overflow: auto;
  top: 55px;
  position: absolute;
  width: 100%;
}

.user-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .left-side {
    .title {
      font-weight: bold;
      font-size: 22px;
    }

    .total {
      margin-left: 15px;
      font-weight: normal;
    }
  }

  .el-button--custom {
    font-weight: bolder;
  }

  .right-side {
    display: flex;
    align-items: center;

    .text-uppercase {
      font-weight: 700;
    }

    div {
      margin-right: 20px;
    }

    .button__upload {
      padding: 10px 25px 9px;
      background-color: var(--buttonThemeColor);
      border: none;
      font-size: 16px;
      line-height: 19px;
      color: white;
      border-radius: 5px;
    }
  }
}

.branding-logo-position {
  margin-top: 30px;
  .positions {
    display: flex;
    margin-top: 15px;
    .el-row {
      margin-right: 15px;
    }
  }
}

.user-list-table {
  min-width: 900px;

  p {
    margin-bottom: 5px;
  }

  .table-header {
    display: flex;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .contact-row {
    width: 19%;

    p.name {
      font-weight: bold;
    }

    p {
      margin-bottom: 5px;
      color: #2c3e50;
    }
  }

  .status-row {
    width: 10%;
  }

  .plan-row {
    width: 10%;
  }

  .joined-row {
    width: 10%;
  }

  .last-activity-row {
    width: 17%;
  }

  .asset-row {
    width: 17%;
  }

  .table-body {
    .plan-row,
    .joined-row,
    .last-activity-row,
    .asset-row {
      color: #485050cf;
    }
  }

  .action-row {
    width: 17%;
    display: inline-grid;

    button {
      background: none;
      border: 0px;
      text-align: left;
    }
  }

  .table-row {
    display: flex;
    padding-bottom: 30px;

    .actions {
      display: block;
    }
  }
}

.color-red {
  color: #d93636;
}

.color-pink {
  color: #ea00d8;
}

.color-green {
  color: #0bd603;
}

.color-blue {
  color: #0bacdb;
}

.textUppercase {
  text-transform: uppercase;
}
.text-white {
  color: var(--textThemeColor);
}
</style>

