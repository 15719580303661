import axios from 'axios'

const actions = {
  async getOneProject ({ commit }, id) {
    return await axios
      .get(`projects/show?id=${id}`)
      .then(res => {
        commit('changeCurrentProject', res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export default actions

