<template>
  <div class="card-container email-setting">
    <div>
      <!-- <h2 class="whitelable-page-heading">SMTP Settings</h2> -->
      <div class="email-head d-flex align-items-center">
        <h3 class="head-title mr-3">SMTP Settings</h3>
        <el-button
          type="primary"
          v-if="testConnection()"
          plain
          @click="testEmailConnection()"
          >Test Connection</el-button
        >
      </div>

      <p class="head-title mr-3">Email Header</p>

      <el-row>
        <el-col :span="12" class="pr-1">
          <span class="key-label mb-5">From name</span>
          <input class="expand field" v-model="email_detail.from_name" />
        </el-col>
        <el-col :span="12">
          <span class="key-label mb-5">From Email Address</span>
          <input class="expand field" v-model="email_detail.from_email" />
        </el-col>
      </el-row>

      <p class="head-title mr-3">Email Footer (CAN SPAM)</p>

      <el-row>
        <el-col :span="12">
          <div class="plan-tags mb-5">
            <div class="mt-4">
              <span class="key-label min65">Company Name:</span>

              <input
                class="expand field"
                type="text"
                v-model="email_detail.company_name"
              />
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="plan-tags mb-5">
            <div class="mt-4">
              <span class="key-label min65">Address:</span>
              <input class="expand field" v-model="email_detail.address" />
            </div>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="plan-tags mb-5">
            <div class="mt-4">
              <span class="key-label min65">City:</span>
              <input class="expand field" v-model="email_detail.city" />
            </div>
          </div>
        </el-col>
      </el-row>

      <p class="head-title mr-3">Authentication</p>
      <el-row>
        <el-col :span="12">
          <div class="plan-tags mb-5">
            <div class="mt-4">
              <span class="key-label min65">SMTP Host:</span>

              <input class="expand field" v-model="email_detail.host" />
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="plan-tags mb-5">
            <div class="mt-4">
              <span class="key-label min65">SMTP Port Number:</span>

              <input class="expand field" v-model="email_detail.port_no" />
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="plan-tags mb-5">
            <div class="mt-4">
              <span class="key-label min65">SMTP Username:</span>

              <input class="expand field" v-model="email_detail.username" />
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="plan-tags mb-5">
            <div class="mt-4">
              <span class="key-label min65">SMTP Password</span>

              <input class="expand field" v-model="email_detail.password" />
            </div>
          </div>
        </el-col>
        <!-- <el-col :span="12">
          <div class="plan-tags mb-5">
            <div class="mt-4">
              <span class="key-label min65">SMTP TLS Status:</span>

              <input class="expand field" v-model="email_detail.tls_status" />
            </div>
          </div>
        </el-col> -->
        <el-col :span="24">
          <div class="mt-4 text-right mb-5">
            <button
              type="button"
              class="btn btn-primary btn-primary-outline"
              @click="storeEmail"
            >
              Save
            </button>
          </div>
        </el-col>
      </el-row>
      <div class="integrations">
        <div
          class="integration-desc"
          :class="this.type === 'on_signup' ? 'is-active' : ''"
        >
          <ul>
            <li @click="storeEmailDetail('on_signup')">On Signup</li>
          </ul>
        </div>
        <div class="aweber-info" v-if="type === 'on_signup'">
          <p>On Signup</p>
          <div class="form-group">
            <label>Subject</label>
            <el-input v-model="on_signup.subject"></el-input>
            <span
              class="error"
              v-if="on_signup.msg !== '' && on_signup.type === 'key'"
            >
              <img
                alt
                class="warning-icon"
                src="../../../assets/img/auth/warning.svg"
              />
              {{ on_signup.msg }}</span
            >
          </div>

          <div class="form-group">
            <label>Email Body</label>
            <!-- <el-input type="textarea" v-model="on_signup.body"></el-input> -->
            <quill-editor
              ref="myQuillEditor"
              v-model="on_signup.body"
              :options="editorOption"
            />
            <span
              class="error"
              v-if="on_signup.msg !== '' && on_signup.type === 'secret'"
            >
              <img
                alt
                class="warning-icon"
                src="../../../assets/img/auth/warning.svg"
              />
              {{ on_signup.msg }}</span
            >
          </div>

          <div class="aweber-info-buttons">
            <el-button type="info" @click="storeEmailDetail">Cancel</el-button>
            <el-button type="primary" @click="storeEmail('on_signup')"
              >Save</el-button
            >
          </div>
        </div>

        <!-- on plan change -->

        <div
          class="integration-desc"
          :class="this.type === 'on_plan_change' ? 'is-active' : ''"
        >
          <ul>
            <li @click="storeEmailDetail('on_plan_change')">
              On Change of Plan
            </li>
          </ul>
        </div>
        <div class="aweber-info" v-if="type === 'on_plan_change'">
          <p>On Change of Plan</p>
          <div class="form-group">
            <label>Subject</label>
            <el-input v-model="on_plan_change.subject"></el-input>
            <span
              class="error"
              v-if="on_plan_change.msg !== '' && on_plan_change.type === 'key'"
            >
              <img
                alt
                class="warning-icon"
                src="../../../assets/img/auth/warning.svg"
              />
              {{ on_plan_change.msg }}</span
            >
          </div>

          <div class="form-group">
            <label>Email Body</label>
            <!-- <el-input type="textarea" v-model="on_plan_change.body"></el-input> -->
            <quill-editor
              ref="myQuillEditorOnPlanChange"
              v-model="on_plan_change.body"
              :options="editorOption"
            />
            <span
              class="error"
              v-if="
                on_plan_change.msg !== '' && on_plan_change.type === 'secret'
              "
            >
              <img
                alt
                class="warning-icon"
                src="../../../assets/img/auth/warning.svg"
              />
              {{ on_plan_change.msg }}</span
            >
          </div>

          <div class="aweber-info-buttons">
            <el-button type="info" @click="storeEmailDetail">Cancel</el-button>
            <el-button type="primary" @click="storeEmail('on_plan_change')"
              >Save</el-button
            >
          </div>
        </div>

        <!-- on cancellation -->

        <div
          class="integration-desc"
          :class="this.type === 'on_cancellation' ? 'is-active' : ''"
        >
          <ul>
            <li @click="storeEmailDetail('on_cancellation')">
              On Cancellation
            </li>
          </ul>
        </div>
        <div class="aweber-info" v-if="type === 'on_cancellation'">
          <p>On Cancellation</p>
          <div class="form-group">
            <label>Subject</label>
            <el-input v-model="on_cancellation.subject"></el-input>
            <span
              class="error"
              v-if="
                on_cancellation.msg !== '' && on_cancellation.type === 'key'
              "
            >
              <img
                alt
                class="warning-icon"
                src="../../../assets/img/auth/warning.svg"
              />
              {{ on_cancellation.msg }}</span
            >
          </div>

          <div class="form-group">
            <label>Email Body</label>
            <!-- <el-input type="textarea" v-model="on_cancellation.body"></el-input> -->
            <quill-editor
              ref="myQuillEditorOnCancellation"
              v-model="on_cancellation.body"
              :options="editorOption"
            />
            <span
              class="error"
              v-if="
                on_cancellation.msg !== '' && on_cancellation.type === 'secret'
              "
            >
              <img
                alt
                class="warning-icon"
                src="../../../assets/img/auth/warning.svg"
              />
              {{ on_cancellation.msg }}</span
            >
          </div>

          <div class="aweber-info-buttons">
            <el-button type="info" @click="storeEmailDetail">Cancel</el-button>
            <el-button type="primary" @click="storeEmail('on_cancellation')"
              >Save</el-button
            >
          </div>
        </div>

        <!-- on account suspention -->

        <div
          class="integration-desc"
          :class="this.type === 'on_account_suspension' ? 'is-active' : ''"
        >
          <ul>
            <li @click="storeEmailDetail('on_account_suspension')">
              On Account Suspension
            </li>
          </ul>
        </div>
        <div class="aweber-info" v-if="type === 'on_account_suspension'">
          <p>On Account Suspension</p>
          <div class="form-group">
            <label>Subject</label>
            <el-input v-model="on_account_suspension.subject"></el-input>
            <span
              class="error"
              v-if="
                on_account_suspension.msg !== '' &&
                on_account_suspension.type === 'key'
              "
            >
              <img
                alt
                class="warning-icon"
                src="../../../assets/img/auth/warning.svg"
              />
              {{ on_account_suspension.msg }}</span
            >
          </div>

          <div class="form-group">
            <label>Email Body</label>
            <!-- <el-input
            type="textarea"
            v-model="on_account_suspension.body"
          ></el-input> -->
            <quill-editor
              ref="myQuillEditorOnSuspension"
              v-model="on_account_suspension.body"
              :options="editorOption"
            />
            <span
              class="error"
              v-if="
                on_account_suspension.msg !== '' &&
                on_account_suspension.type === 'secret'
              "
            >
              <img
                alt
                class="warning-icon"
                src="../../../assets/img/auth/warning.svg"
              />
              {{ on_account_suspension.msg }}</span
            >
          </div>

          <div class="aweber-info-buttons">
            <el-button type="info" @click="storeEmailDetail">Cancel</el-button>
            <el-button
              type="primary"
              @click="storeEmail('on_account_suspension')"
              >Save</el-button
            >
          </div>
        </div>

        <!-- on password reset -->

        <div
          class="integration-desc"
          :class="this.type === 'on_password_reset' ? 'is-active' : ''"
        >
          <ul>
            <li @click="storeEmailDetail('on_password_reset')">
              On Password Reset
            </li>
          </ul>
        </div>
        <div class="aweber-info" v-if="type === 'on_password_reset'">
          <p>On Password Reset</p>
          <div class="form-group">
            <label>Subject</label>
            <el-input v-model="on_password_reset.subject"></el-input>
            <span
              class="error"
              v-if="
                on_password_reset.msg !== '' && on_password_reset.type === 'key'
              "
            >
              <img
                alt
                class="warning-icon"
                src="../../../assets/img/auth/warning.svg"
              />
              {{ on_password_reset.msg }}</span
            >
          </div>

          <div class="form-group">
            <label>Email Body</label>
            <!-- <el-input type="textarea" v-model="on_password_reset.body"></el-input> -->
            <quill-editor
              ref="myQuillEditorOnPassword"
              v-model="on_password_reset.body"
              :options="editorOption"
            />
            <span
              class="error"
              v-if="
                on_password_reset.msg !== '' &&
                on_password_reset.type === 'secret'
              "
            >
              <img
                alt
                class="warning-icon"
                src="../../../assets/img/auth/warning.svg"
              />
              {{ on_password_reset.msg }}</span
            >
          </div>

          <div class="aweber-info-buttons">
            <el-button type="info" @click="storeEmailDetail">Cancel</el-button>
            <el-button type="primary" @click="storeEmail('on_password_reset')"
              >Save</el-button
            >
          </div>
        </div>
      </div>
      <div class="mt-5 text-right mb-5">
        <button
          type="button"
          class="btn btn-primary btn-primary-outline"
          @click="storeEmail"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
var toolbarOptions = ["bold", "italic", "underline", "strike"];

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      c: {},
      type: "on_signup",
      testConn: false,
      on_signup: {
        subject: "",
        body: "",
        msg: "",
        type: "",
      },
      on_plan_change: {
        subject: "",
        body: "",
        msg: "",
        type: "",
      },
      on_cancellation: {
        subject: "",
        body: "",
        msg: "",
        type: "",
      },
      on_account_suspension: {
        subject: "",
        body: "",
        msg: "",
        type: "",
      },

      on_password_reset: {
        subject: "",
        body: "",
        msg: "",
        type: "",
      },

      email_detail: {
        from_name: "",
        from_email: "",
        company_name: "",
        address: "",
        city: "",
        username: "",
        password: "",
        port_no: "",
        tls_status: "",
        connection: "",
        host: "",
      },
      editorOption: {
        modules: {
          toolbar: {
            handlers: {
              modules: {
                toolbar: toolbarOptions,
              },
            },
          },
        },
      },
    };
  },
  computed: {
    editor() {
      this.$refs.myQuillEditorOnPassword.quill;
      this.$refs.myQuillEditorOnSuspension.quill;
      this.$refs.myQuillEditorOnCancellation.quill;
      this.$refs.myQuillEditorOnPlanChange.quill;
      this.$refs.myQuillEditor.quill;
    },
  },
  mounted() {
    // console.log("this is current quill instance object", this.editor);
  },
  created() {
    this.getEmailDetail();
    this.user = JSON.parse(localStorage.getItem("user_info"));
  },
  methods: {
    testConnection() {
      console.log(this.email_detail, "-*-*-*");

      if (
        this.email_detail.from_name !== null &&
        this.email_detail.from_name !== "" &&
        this.email_detail.from_email !== null &&
        this.email_detail.from_email !== "" &&
        this.email_detail.username !== null &&
        this.email_detail.username !== "" &&
        this.email_detail.password !== null &&
        this.email_detail.password !== "" &&
        this.email_detail.port_no !== null &&
        this.email_detail.port_no !== "" &&
        this.email_detail.host !== null &&
        this.email_detail.host !== ""
      ) {
        // alert('jhdgdfdhfg');
        this.testConn = true;
      }

      return this.testConn;
    },
    storeEmailDetail(type = "") {
      this.type = type;
    },

    testEmailConnection() {
      let post_data = {
        url: "/whitelabel/emails/test",
      };
      var $this = this;

      this.$store.dispatch("post", { ...post_data }).then((response) => {

         $this.$message({
            showClose: true,
            message: response.data.message,
            type: "success",
          });

      });
    },

    getEmailDetail() {
      let post_data = {
        url: "/whitelabel/emails/list",
      };
      var $this = this;

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          this.email_detail.from_name = response.data.setting.from_name;
          this.email_detail.from_email = response.data.setting.from_email;
          this.email_detail.company_name = response.data.setting.company_name;
          this.email_detail.address = response.data.setting.address;
          this.email_detail.city = response.data.setting.city;
          this.email_detail.username = response.data.setting.username;
          this.email_detail.password = response.data.setting.password;
          this.email_detail.port_no = response.data.setting.port_no;
          this.email_detail.tls_status = response.data.setting.tls_status;
          this.email_detail.connection = response.data.setting.connection;
          this.email_detail.host = response.data.setting.host;

          this.on_signup.subject = response.data.setting.signup_subject;
          this.on_signup.body = response.data.setting.signup_body;

          this.on_plan_change.subject =
            response.data.setting.change_plan_subject;
          this.on_plan_change.body = response.data.setting.change_plan_body;

          this.on_cancellation.subject =
            response.data.setting.cancellation_subject;
          this.on_cancellation.body = response.data.setting.cancellation_body;

          this.on_account_suspension.subject =
            response.data.setting.suspension_subject;
          this.on_account_suspension.body =
            response.data.setting.suspension_body;

          this.on_password_reset.subject =
            response.data.setting.pass_reset_subject;
          this.on_password_reset.body = response.data.setting.pass_reset_body;

          console.log(response.data, "=========");
        })
        .catch((error) => {});
    },
    storeEmail(name) {
      var subject = "";
      var body = "";

      if (name === "on_signup") {
        subject = this.on_signup.subject;
        body = this.on_signup.body;

        if (this.on_signup.subject === "") {
          this.on_signup.msg = "Please enter subject";
          this.on_signup.type = "key";
          return false;
        } else if (this.on_signup.body === "") {
          this.on_signup.msg = "Please enter body";
          this.on_signup.type = "secret";
          return false;
        } else {
          this.on_signup.msg = "";
          this.on_signup.type = "";
        }
      }

      if (name === "on_plan_change") {
        subject = this.on_plan_change.subject;
        body = this.on_plan_change.body;

        if (this.on_plan_change.subject === "") {
          this.on_plan_change.msg = "Please enter subject";
          this.on_plan_change.type = "key";
          return false;
        } else if (this.on_plan_change.body === "") {
          this.on_plan_change.msg = "Please enter body";
          this.on_plan_change.type = "secret";
          return false;
        } else {
          this.on_plan_change.msg = "";
          this.on_plan_change.type = "";
        }
      }

      if (name === "on_cancellation") {
        subject = this.on_cancellation.subject;
        body = this.on_cancellation.body;

        if (this.on_cancellation.subject === "") {
          this.on_cancellation.msg = "Please enter subject";
          this.on_cancellation.type = "key";
          return false;
        } else if (this.on_cancellation.body === "") {
          this.on_cancellation.msg = "Please enter body";
          this.on_cancellation.type = "secret";
          return false;
        } else {
          this.on_cancellation.msg = "";
          this.on_cancellation.type = "";
        }
      }

      if (name === "on_account_suspension") {
        subject = this.on_account_suspension.subject;
        body = this.on_account_suspension.body;

        if (this.on_account_suspension.subject === "") {
          this.on_account_suspension.msg = "Please enter subject";
          this.on_account_suspension.type = "key";
          return false;
        } else if (this.on_account_suspension.body === "") {
          this.on_account_suspension.msg = "Please enter body";
          this.on_account_suspension.type = "secret";
          return false;
        } else {
          this.on_account_suspension.msg = "";
          this.on_account_suspension.type = "";
        }
      }

      if (name === "on_password_reset") {
        subject = this.on_password_reset.subject;
        body = this.on_password_reset.body;

        if (this.on_password_reset.subject === "") {
          this.on_password_reset.msg = "Please enter subject";
          this.on_password_reset.type = "key";
          return false;
        } else if (this.on_password_reset.body === "") {
          this.on_password_reset.msg = "Please enter body";
          this.on_password_reset.type = "secret";
          return false;
        } else {
          this.on_password_reset.msg = "";
          this.on_password_reset.type = "";
        }
      }

      let post_data = {
        url: "/whitelabel/emails/store",
        data: {
          on_signup: this.on_signup,
          on_plan_change: this.on_plan_change,
          on_cancellation: this.on_cancellation,
          on_account_suspension: this.on_account_suspension,
          on_password_reset: this.on_password_reset,
          email_detail: this.email_detail,
        },
      };
      var $this = this;

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          this.testConnection();
          $this.$message({
            showClose: true,
            message: "Detail successfully saved",
            type: "success",
          });
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="less">
.email-setting {
  // left: 150px;
  // position: absolute;
  //width: 100%;
  //height: calc(~"100vh - 120px");
  // top: 55px;
  padding: 0 30px 80px 30px;

  form {
    padding: 20px;
    background: #f9fbfb;
    > div {
      max-width: 1050px;
    }
  }
  .email-body-header {
    height: 70px;
    background: gray;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  .el-textarea {
    textarea {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  .d-flexx {
    .el-form-item__content {
      display: flex;
    }
    .el-form-item__label {
      min-width: 180px;
      text-align: left;
    }
  }
  .signup-box {
    background: #ebf9fa;
    padding: 15px 35px;
    border-radius: 6px;
    .el-form-item__label {
      text-transform: uppercase;
    }
  }
  h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 20px 25px 20px 0;
    color: #0c4077;
  }
  p.head-title {
    font-size: 16px;
    font-weight: bold;
    margin: 30px 25px 0 0;
    color: #0c4077;
    &.tag {
      margin: 40px 25px 40px 0;
    }
    span {
      font-weight: normal;
    }
  }
  .integration-desc {
    margin-top: 20px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      max-width: 1200px;
      li {
        min-height: 55px;
        width: 100%;
        display: flex;
        align-items: center;
        background: #05308614;
        border-radius: 6px;
        color: #282828;
        padding: 0 30px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }
    }
    &.is-active {
      li {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  .el-button--primary {
    color: #fff;
    background-color: #0a44b1;
    border-color: #0a44b1;
  }
  .el-button--info {
    color: #fff;
    background-color: #a2adad;
    border-color: #a2adad;
  }
  .aweber-info-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }
  .email-head {
    align-items: center;
    width: 100%;
  }
  .el-button--primary.is-plain {
    background: #ebf9fa;
    border-color: #33acdb;
    border-radius: 40px;
    color: #406e97;
    height: 45px;
  }
  .expand.field {
    input {
      max-width: 400px;
    }
  }
  .aweber-info {
    padding: 30px 25px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-width: 1200px;
    border-top: none;
    border: 2px solid #ebeef5;

    p {
      font-size: 18px;
      font-weight: bold;
      color: #0c4077;
    }
  }
}
.text-white {
  color: var(--textThemeColor) !important;
}

.text-white {
  color: var(--textThemeColor) !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}
.mt-4 {
  margin-top: 4px !important;
}
.min65 {
  min-width: 150px !important;
}
.plan-tags {
  max-width: 100% !important;
}
</style>
