<template>
  <div class="first-access-holder">
    <tmp-app-header
      
      :user="user"
      :whitelabelBrandDetail="whitelabelBrandDetail"
    ></tmp-app-header>
    <router-view :user="user"></router-view>
  </div>
</template>

<script>
import TmpAppHeader from "./../../components/App/TmpAppHeader.vue";
export default {
  name: "FirstAccess",
  components: {
    TmpAppHeader,
  },
  data() {
    return {
      user: {},
      whitelabelBrandDetail: null
    };
  },
  created() {
      console.log(document.querySelector('#app'));
    this.user = JSON.parse(localStorage.getItem("user_info"));
  },
  mounted(){
        
  }
};
</script>
<style lang="less" scoped>
.first-access-holder {
  width: 768px;
  max-width: 100%;
  background: #ffffff;
  margin: 35px auto;
  padding: 20px 30px;
  min-height: 400px;
  box-shadow: 0 60px 80px 50px #080e540f;
  border-radius: 20px;
}
</style>