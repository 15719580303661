import { render, staticRenderFns } from "./ConfirmModal.vue?vue&type=template&id=2d2e8880&scoped=true&"
import script from "./ConfirmModal.vue?vue&type=script&lang=js&"
export * from "./ConfirmModal.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmModal.vue?vue&type=style&index=0&id=2d2e8880&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d2e8880",
  null
  
)

export default component.exports