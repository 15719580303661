import AppHelper from "../../utils/AppHelper";
import Vue from 'vue';
export default {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    insert(state, payload) {
      state.items.push(payload);
    }
  },
  getters: {
    getByHash: (state) => (hash) => {
      return state.items.find(item => item.hashID === hash);
    }
  },
  actions: {
    async upload({dispatch, commit, state}, payload) {
      const videoMeta = await AppHelper.getMetaFromVideoFile(payload.file);
      const imageSource = videoMeta.thumbnail;
      const duration = videoMeta.duration;
      let row = {
        hashID: (state.items.length + 1) + '_' + new Date().getTime(),
        thumbnail: imageSource,
        is_uploading: true,
        views: 0,
        leads: 0,
        transcoding_size_source: payload.file.size,
        title: AppHelper.getNameFromFileName(payload.file.name),
        id: new Date().getTime(),
        updated_at: AppHelper.getFormatDate(new Date()),
        duration: duration,
        progress: 0,
        remaining: 'Waiting...',
        upload: {
          timeStarted: new Date().getTime(),
          remainingText: 'Waiting...',
          remainingSeconds: false,
        }
      };
      Vue.axios.post(process.env.BACKEND_URL + 'upload_video/get_key',{
        filename: payload.file.name,
        title: row.title,
        user_id: payload.user.id,
        project_id: payload.projectId
      }).then(res => {
        const formData = new FormData();
        formData.append('key', res.data['key']);
        formData.append('AWSAccessKeyId', res.data['AWSAccessKeyId']);
        formData.append('policy', res.data['policy']);
        formData.append('signature', res.data['signature']);
        formData.append('file', payload.file);
        Vue.axios.post(res.data['url'], formData, {
          onUploadProgress: progressEvent => {
            let percent = Math.round(100 * progressEvent.loaded / progressEvent.total);
            percent = percent < 95 ? percent : 95;
            row.progress = percent;
            const timeElapsed = (new Date().getTime() - row.upload.timeStarted) / 1000;
            const uploadSpeed = progressEvent.loaded / timeElapsed;
            row.upload.remainingSeconds = (progressEvent.total - progressEvent.loaded) / uploadSpeed;
            if (Math.floor(row.upload.remainingSeconds / 60) > 0) {
              row.upload.remainingText = Math.ceil(row.upload.remainingSeconds / 60) + ' minutes remaining';
            } else {
              row.upload.remainingText = Math.ceil(row.upload.remainingSeconds) + ' seconds remaining';
            }
          }
        }).then(awsRes => {
          row.progress = 99;
          Vue.axios.post(process.env.BACKEND_URL + 'upload_video/upload_finish', JSON.stringify({
            'filename': res.data['key'],
            'id': res.data['id'],
            'email': payload.user.email.replace(/\.|@/g,"")
          })).then(finalRes => {
            row.progress = 100;
            row.id = res.data['id'];
            row.is_uploading = false;
          });
        });
      });
      commit('insert', row);
      return row;
    }
  }
}
