<template>
  <div class="card-container brand-setting ">
      <h2 class="whitelable-page-heading">Branding</h2>
    <el-form>
        <el-row class="">
          <el-col :span="24">
            <div class="">
              <span class="key-label">App Name</span>
              <div>
                <input
                  class="expand field"
                  v-model="brandDetail.app_name"
                  @keyup="storeAppName"
                />
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="mt-4">
              <span class="key-label image">App Logo</span>

              <!-- <el-upload
          drag
          :show-file-list="false"
          :on-progress="handleImageUploadProgress"
          :on-success="handleImageUploadSuccess"
          :before-upload="handleBeforeImageUpload"
          :action="this.endpoint"
        >
          <button
            type="button"
            class="btn btn-default btn-primary-outline"
          >
            Add Picture
          </button>
        </el-upload> -->
              <div>
                <profile-upload
                  :headers="headers"
                  :height="90"
                  :no-rotate="false"
                  :params="params"
                  :url="endpoint"
                  :width="160"
                  @src-file-set="srcFileSet"
                  @crop-success="cropSuccess"
                  @crop-upload-fail="cropUploadFail"
                  @crop-upload-success="cropUploadSuccess"
                  field="file"
                  lang-type="en"
                  ref="avatar_uploader"
                  v-model="showPictureModal"
                ></profile-upload>

                <div style="margin: 15px 0">
                  <img
                    :src="this.brandDetail.app_logo"
                    v-if="brandDetail.app_logo !== null"
                    height="90"
                    width="160"
                  />
                </div>
                <button
                  type="button"
                  class="btn btn-default btn-primary-outline btn-primary"
                  @click="showPictureModal = true"
                >
                  Add Picture
                </button>
              </div>

              <!-- <button
          data-v-2606308e=""
          type="button"
          class="btn btn-default btn-primary-outline"
        >
          Add Picture
        </button> -->
            </div>
          </el-col>
          <el-col :span="12">
            <div class="mt-4">
              <span class="key-label">App Favicon</span>

              <profile-upload
                :headers="headers"
                :height="90"
                :no-rotate="false"
                :params="params"
                :url="faviconendpoint"
                :width="90"
                @crop-success="faviconcropSuccess"
                @crop-upload-fail="faviconcropUploadFail"
                @crop-upload-success="faviconcropUploadSuccess"
                field="file"
                lang-type="en"
                ref="favicon_uploader"
                v-model="faviconshowPictureModal"
              ></profile-upload>

              <div style="margin: 15px 0">
                <img
                  :src="this.brandDetail.favicon_logo"
                  v-if="brandDetail.favicon_logo !== null"
                  height="90"
                  width="90"
                />
              </div>
              <button
                type="button"
                class="btn btn-default btn-primary"
                @click="faviconshowPictureModal = true"
              >
                Add Picture
              </button>
            </div>
          </el-col>
        </el-row>

        <div class="mt-4">
          <span class="key-label">App Theme Color</span>
          <div class="d-flex align-items-center color-holder">
            
            <div class="color-feild">
              Background Color<br /><el-color-picker
                v-model="brandDetail.backgroundColor"
                @active-change="changeColor($event, 'background')"
              ></el-color-picker>
            </div>
            <div class="color-feild">
              Button Color<br /><el-color-picker
                v-model="brandDetail.buttonColor"
                @active-change="changeColor($event, 'button')"
              ></el-color-picker>
            </div>
            <div class="color-feild">
              Text Color<br /><el-color-picker
                v-model="brandDetail.textColor"
                @active-change="changeColor($event, 'text')"
              ></el-color-picker>
            </div>
          </div>

          <!-- <el-radio-group v-model="brandDetail.theme_color">
          <el-radio :label="dark blue, white, royal blue">dark blue, white, royal blue</el-radio>
          <el-radio :label="dark green, white, royal blue">dark green, white, royal blue</el-radio>
          <el-radio :label="dark red, white, royal blue">dark red, white, royal blue</el-radio>
          </el-radio-group> -->
        </div>

        <div class="mt-4 d-flex align-items-center">
          <span class="key-label pb-1">App Root Domain</span>
          <div class="d-flex integrationButtons">
            <button
              class="btn btn-primary"
              @click="openDomainDialog()"
              type="button"
              v-if="domain === null"
            >
              Add New Domain
            </button>
          </div>
        </div>

        <div
          class="root-domain d-flex"
          v-if="instance != null && domain && domain.custom_domain === null"
        >
          <span>
            <span class="url ml-2">{{ instance }}</span>
          </span>

          <span class="secure button"> </span>
          <span class="delete button">
            <button class="btn btn-danger" type="button">Copy</button>
          </span>
        </div>

        <div
          class="root-domain d-flex"
          v-if="domain != null && domain.custom_domain !== null"
        >
          <span>
            <!-- <el-switch
              v-model="domain.is_enabled"
              @change="updateEnableStatus(domain)"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch> -->
            <span class="url ml-2">{{ domain.custom_domain }}</span>
          </span>

          <span class="secure button">
            <div class="domain-type" :class="domain.status">
              <span>{{ domain.status }}</span>
            </div>
          </span>
          <span class="delete button">
            <button
              class="btn btn-danger"
              type="button"
              @click="
                showDeleteModal = true;
                deleteId = domain.id;
              "
            >
              Delete
            </button>
          </span>
        </div>
        <!-- <div class="mt-4 d-flex align-items-center">
          <span class="key-label">Api Key</span>
          <span class="key-code">{{ brandDetail.api_key }}</span>
          <span class="reset-key-label" @click="resetApi">Reset Key</span>
        </div> -->
        <el-row>
          <el-col :span="12" class="pr-2">
            <div class="mt-4">
              <span class="key-label pb-1">Help Link</span>
              <div>
                <input
                  class="expand field"
                  v-model="brandDetail.help_link"
                  @keyup="storeAppName"
                />
                <div
                  v-if="linkValidation.type === 'help'"
                  style="color: #f53c3c"
                >
                  {{ linkValidation.message }}
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="mt-4">
              <span class="key-label pb-1">Privacy Link</span>
              <div>
                <input
                  class="expand field"
                  v-model="brandDetail.privacy_link"
                  @keyup="storeAppName"
                />
                <div
                  v-if="linkValidation.type === 'privacy'"
                  style="color: #f53c3c"
                >
                  {{ linkValidation.message }}
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12" class="pr-2">
            <div class="mt-4">
              <span class="key-label">Terms Link</span>
              <div>
                <input
                  type="link"
                  class="expand field"
                  v-model="brandDetail.terms_link"
                  @keyup="storeAppName"
                />
                <div
                  v-if="linkValidation.type === 'terms'"
                  style="color: #f53c3c"
                >
                  {{ linkValidation.message }}
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="mt-4">
              <span class="key-label">Chat script</span>
              <div>
                <input
                  type="textarea"
                  class="expand field"
                  v-model="brandDetail.chat_script"
                  @keyup="storeAppName"
                />
              </div>
            </div>
          </el-col>
        </el-row>
        <div
          class="mt-4 d-flex align-items-center"
          style="float: right"
          @click="$router.push('/whitelabel/settings/branding')"
        >
          <el-button type="custom" class="button__upload"> Save </el-button>
        </div>
      </el-form>

    <el-dialog
      title="CONNECT NEW DOMAIN NAME"
      :center="true"
      :close-on-click-modal="false"
      :top="mobileCheck ? `10vh` : `30vh`"
      :visible.sync="domainDialogShow"
      :width="mobileCheck ? `100%` : `736px`"
      :height="mobileCheck ? `100%` : `700px`"
      class="new-integration-dialog"
    >
      <!--            <template slot="title">-->
      <!--                Connect new domain name-->
      <!--            </template>-->

      <!-- form data  -->
      <div class="form-group row-section m-top-4">
        <label class="control-label">Enter domain name</label>
        <el-input
          type="text"
          placeholder="sub.domain.com (eg: www.tiffycookingchannel.com or videos.tiffycookingchannel.com)"
          v-model="domainData.name"
          class="custom-el-input"
        ></el-input>
        <span v-if="this.validDomain" class="error">{{
          this.validDomain
        }}</span>
      </div>

      <div class="dialog-footer" slot="footer">
        <el-button @click="domainDialogShow = false" round type="light"
          >Cancel
        </el-button>
        <el-button @click="createDomain" round type="primary">
          Continue
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="COMPLETE DOMAIN SETUP"
      :center="true"
      :close-on-click-modal="false"
      :top="mobileCheck ? `10vh` : `30vh`"
      :visible.sync="domainCompleteDialogShow"
      :width="mobileCheck ? `100%` : `736px`"
      :height="mobileCheck ? `100%` : `700px`"
      class="new-integration-dialog"
    >
      <div class="form-group row-section m-top-4">
        <span
          >In order for your custom domain to work you'll need to log in to
          where you bought your domain and add a CNAME record that points
          <span class="text-green">{{ domainText }}</span> at
          <span class="text-green">4kplayer.bigcommand.com</span>. If you are not
          sure how to do this,
          <a
            href="https://help.bigcommand.com/article/96-connecting-your-domain-to-adilo"
            target="_blank"
            >click here to watch a simple tutorial.</a
          ></span
        >
      </div>

      <div class="dialog-footer" slot="footer">
        <el-button @click="domainCompleteDialogShow = false" round type="light"
          >Cancel
        </el-button>
        <el-button @click="completeDomainSetup()" round type="primary">
          Complete
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="ARE YOU SURE TO DELETE THIS DOMAIN?"
      center
      :width="`40%`"
      class="el-dialog--huge dialog_border_radius del-popup"
      :visible.sync="showDeleteModal"
      @close="$emit('trigger', false)"
      :modalAppendToBody="false"
    >
      <div style="line-height: 2">You are about to Delete this Domain.</div>

      <span
        slot="footer"
        class="dialog-footer"
        :class="{ 'error-footer': isTypedDeleteError }"
      >
        <div
          class="mb-20 mt-20"
          style="padding-right: 30px; padding-left: 30px"
        >
          <label style="color: #ee5951">
            Type
            <b style="color: red">DELETE</b> to confirm
          </label>
          <div class="row-space-top-1 el-input">
            <input
              type="text"
              autocomplete="off"
              v-model="typeConfirmDelete"
              class="el-input__inner custom_input_new_style"
            />
          </div>

          <span style="color: #ee5951" v-if="isTypedDeleteError">
            <i class="el-icon-warning" style="color: #ee5951"></i> You must type
            <b style="color: #ee5951">DELETE</b> to confirm
          </span>
        </div>
        <div class="footer-button">
          <el-button @click="showDeleteModal = false" type="gray" round
            >Cancel</el-button
          >
          <el-button type="danger" @click="deleteDomainConfirm" round
            >Delete</el-button
          >
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import profileUpload from "vue-image-crop-upload";
export default {
  components: { profileUpload },
  data() {
    return {
    
      form: {
        name: "",
        logo: "",
        value: "100",
      },
      headers: {
        Authorization: "",
      },
      showPictureModal: false,
      faviconshowPictureModal: false,
      brandDetail: {
        app_name: "",
        app_logo: null,
        api_key: "",
        app_domain: "",
        app_domain_status: "",
        help_link: "",
        privacy_link: "",
        terms_link: "",
        theme_color: "",
          backgroundColor: "#101A3B",
      buttonColor: "#0044b1",
      textColor: "#fff",
      chat_script: null
      },
      linkValidation: {
        type: "",
        message: "",
      },
      domainDialogShow: false,
      domainData: {
        name: "",
      },
      showDeleteModal: false,
      typeConfirmDelete: "",
      validDomain: "",
      domainCompleteDialogShow: false,
      checkLimit: false,
      domain: null,
      deleteId: null,
      isTypedDeleteError: false,
      domainText: "",
      endpoint:
        process.env.VUE_APP_WHITELABEL_URL +
        "api/whitelabel/image/store/brand-logo",
      faviconendpoint:
        process.env.VUE_APP_WHITELABEL_URL +
        "api/whitelabel/image/store/favicon-logo",
      upload_in_progress: false,
      invalidFileFormat: false,
      brandLogo: null,
      instance: null,
      params: {
        id: 0,
      },

    };
  },
  computed: {
    mobileCheck: {
      get() {
        let BC_onMobile = false;
        if (
          window.screen.availWidth <= 1024 ||
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
        ) {
          BC_onMobile = true;
        }

        return BC_onMobile;
      },
    },
  },
  created() {
    this.getBrandDetail();
    this.getDomains();
  },
  methods: {
    handleImageUploadProgress(ev, file, rawFile) {
      this.upload_in_progress = true;
      if (ev.total > 0) {
        let percentage = parseInt((ev.loaded / ev.total) * 100) || 0;
        if (percentage === 100) {
          this.$Progress.finish();
          this.upload_in_progress = false;
        } else {
          this.$Progress.update(percentage);
        }
      }
    },
    handleBeforeImageUpload(file) {
      // const isLt2M = file.size / 1024 / 1024 < 8;
      // if (!isLt2M) {
      //     this.$message.error('Image picture size can not exceed 8MB!');
      //     return false;
      // }
      console.log(file, file.type, "===", allowedType);

      // var allowedType = ['text/vtt'];
      var allowedType = ["application/x-subrip", "text/vtt"];
      // var valid = file.type.match(allowedType);
      // if (!valid) {

      // if (file.name.substring(file.name.length - 4) === ".sbv" || file.name.substring(file.name.length - 4) === ".srt") {
      //     document.getElementById('upload_srt_file').classList.remove('error')
      //     this.invalidFileFormat = false;
      //     this.$Progress.start();
      //     return true;
      // } else if (allowedType.indexOf(file.type) === -1) {
      //     this.invalidFileFormat = true;
      //     document.getElementById('upload_srt_file').classList.add('error')
      //     return false;
      // } else {
      //     // this.$message.error('Image picture must be SRT format!');
      //     document.getElementById('upload_srt_file').classList.remove('error')
      //     this.invalidFileFormat = false;
      //     this.$Progress.start();
      //     return true;
      // }
    },
    cropSuccess(imgDataUrl, field) {
      // console.log(imgDataUrl, field, "cropSuccess");
      // this.user.photo_url = imgDataUrl;
      // this.user.settings.user_image = imgDataUrl;
      // this.user.settings.avatar = imgDataUrl;
      // this.params.id = this.user.id;
    },
    cropUploadFail(status, field) {
      if (status === 401) {
      } else {
        console.log(status);
      }
    },
    cropUploadSuccess(res, field) {
      // console.log(jsonData, field, "cropSuccess");

      // this.upload_in_progress = false;

      if (res.success) {
        this.showPictureModal = false;
        this.addedProfilePicture = true;
        this.$refs.avatar_uploader.setStep(1);

        // this.changed.changed_profile = true;
        // this.brandLogo = res.logo;
        this.brandDetail.app_logo = res.logo.app_logo;
      }
    },
    faviconcropSuccess(imgDataUrl, field) {
      // console.log(imgDataUrl, field, "cropSuccess");
      // this.user.photo_url = imgDataUrl;
      // this.user.settings.user_image = imgDataUrl;
      // this.user.settings.avatar = imgDataUrl;
      // this.params.id = this.user.id;
    },
    faviconcropUploadFail(status, field) {
      if (status === 401) {
      } else {
        console.log(status);
      }
    },
    faviconcropUploadSuccess(res, field) {
      // console.log(jsonData, field, "cropSuccess");

      this.upload_in_progress = false;

      if (res.success) {
        this.faviconshowPictureModal = false;
        // this.addedProfilePicture = true;
        this.$refs.favicon_uploader.setStep(1);

        // this.changed.changed_profile = true;
        // this.brandLogo = res.logo;
        this.brandDetail.favicon_logo = res.logo.favicon_logo;
      }
    },
    handleImageUploadSuccess(res, file) {
      this.upload_in_progress = false;

      if (res.success) {
        // this.brandLogo = res.logo;
        this.brandDetail.app_logo = res.logo.app_logo;
      }
    },
    openDomainDialog() {
      this.checkLimit = false;
      this.domainDialogShow = true;
    },
    completeDomainSetup() {
      this.domainCompleteDialogShow = false;

      if (this.domain) {
        this.axios({
          method: "post",
          url: `checkDnsStatus/${this.domain.id}`,
          data: {
            domain: this.domain,
          },
        })
          .then((response) => {
            this.domain = response.data;
          })
          ["catch"]((error) => {});
      }
    },
    editDomain(domain) {
      this.axios({
        method: "post",
        url: `domain/${domain.id}`,
        data: {
          domain: domain,
        },
      })
        .then((response) => {
          this.domain_id = null;
        })
        ["catch"]((error) => {
          var errors = error.response.data.errors;

          if (
            typeof error.response.data.errors["domain.home_page_redirect"] !==
            "undefined"
          ) {
            this.validate.homepage =
              error.response.data.errors["domain.home_page_redirect"][0];
          }

          if (
            typeof error.response.data.errors["domain.error_redirect"] !==
            "undefined"
          ) {
            this.validate.err404 =
              error.response.data.errors["domain.error_redirect"][0];
          }
        });
    },
    updateEnableStatus(domain) {
      this.axios({
        method: "post",
        url: `updateStatus/${domain.id}`,
        data: {
          domain: domain,
        },
      })
        .then((response) => {})
        ["catch"]((error) => {});
    },
    deleteDomainConfirm() {
      if (this.typeConfirmDelete === "DELETE") {
        this.isTypedDeleteError = false;
        let $this = this;

        this.axios({
          method: "delete",
          url: `domain/${this.deleteId}`,
        })
          .then((response) => {
            $this.typeConfirmDelete = true;
            $this.typeConfirmDelete = "";
            $this.showDeleteModal = false;

            this.getDomains();
          })
          ["catch"]((error) => {});
      } else {
        this.isTypedDeleteError = true;
      }
    },
    createDomain() {
      if (this.domainData.name === "") {
        this.validDomain = "Please enter domain";
        return false;
      }

      if (this.domainData.name !== "") {
        var res = this.domainData.name.split(".");

        var regex = "/^[a-z](.*[a-z])?$/igm";
        var lastChar = this.domainData.name.substr(
          this.domainData.name.length - 1
        );

        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (!format.test(lastChar)) {
          this.validDomain = "";
          console.log(true);
        } else {
          this.validDomain = "Please enter valid domain";
          console.log(false);
          return false;
        }

        if (res.length < 3) {
          this.validDomain = "Please enter valid domain";
          return false;
        } else {
          this.validDomain = "";
        }
      }

      this.axios
        .post("domain", this.domainData)
        .then((r) => {
          this.getDomains();

          this.domain = r.data.domain;
          var data = r.data.domain.custom_domain.split(".");
          this.domainText = data[0];
          // this.user.domains = r.data.domains;
          // this.domains.push(r.data);
          this.domainDialogShow = false;
          this.domainData.name = "";
          if (r.data.domain.status === "Unverified") {
            this.domainCompleteDialogShow = true;
            console.log(r, "=== ***");
          }
        })
        ["catch"]((error) => {
          console.log(error, "===");
          var errors = error.response.data.errors;

          if (typeof errors !== "undefined") {
            this.validDomain = errors["name"][0];
            return false;
          } else {
            this.validDomain = "";
          }
        });
    },
    getDomains() {
      this.axios.get("domain").then((r) => {
        this.domain = r.data.domain;
        // this.$forceUpdate();
      });
    },
    resetApi() {
      let post_data = {
        url: "/whitelabel/reserApi",
      };

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          this.brandDetail.api_key = response.data.detail.api_key;
        })
        .catch((error) => {});
    },
    validURL(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },
    storeAppName() {
      var $this = this;

      if (
        $this.brandDetail.help_link !== "" &&
        $this.brandDetail.help_link !== null
      ) {
        var valid = this.validURL($this.brandDetail.help_link);

        if (!valid) {
          this.linkValidation.type = "help";
          this.linkValidation.message = "Please enter valid url";
          return false;
        } else {
          this.linkValidation.type = "";
          this.linkValidation.message = "";
        }
      }

      if (
        $this.brandDetail.privacy_link !== "" &&
        $this.brandDetail.privacy_link !== null
      ) {
        var valid = this.validURL($this.brandDetail.privacy_link);

        if (!valid) {
          this.linkValidation.type = "privacy";
          this.linkValidation.message = "Please enter valid url";
          return false;
        } else {
          this.linkValidation.type = "";
          this.linkValidation.message = "";
        }
      }

      if (
        $this.brandDetail.terms_link !== "" &&
        $this.brandDetail.terms_link !== null
      ) {
        var valid = this.validURL($this.brandDetail.terms_link);

        if (!valid) {
          this.linkValidation.type = "terms";
          this.linkValidation.message = "Please enter valid url";
          return false;
        } else {
          this.linkValidation.type = "";
          this.linkValidation.message = "";
        }
      }

      this.linkValidation.type = "";
      this.linkValidation.message = "";

      let post_data = {
        url: "/whitelabel/storeAppName",
        data: {
          app_name: $this.brandDetail.app_name,
          help_link: $this.brandDetail.help_link,
          privacy_link: $this.brandDetail.privacy_link,
          terms_link: $this.brandDetail.terms_link,
          backgroundColor: $this.brandDetail.backgroundColor,
          buttonColor: $this.brandDetail.buttonColor,
          textColor: $this.brandDetail.textColor,
          chat_script: $this.brandDetail.chat_script
        },
      };

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {

        })
        .catch((error) => {});
    },
        rgbToHex(color) {
      color = "" + color;
      if (!color || color.indexOf("rgb") < 0) {
        return;
      }

      if (color.charAt(0) == "#") {
        return color;
      }

      var nums = /(.*?)rgb\((\d+),\s*(\d+),\s*(\d+)\)/i.exec(color),
        r = parseInt(nums[2], 10).toString(16),
        g = parseInt(nums[3], 10).toString(16),
        b = parseInt(nums[4], 10).toString(16);

      return (
        "#" +
        ((r.length == 1 ? "0" + r : r) +
          (g.length == 1 ? "0" + g : g) +
          (b.length == 1 ? "0" + b : b))
      );
    },
    changeColor(color, type) {
      if (type === "background") {
        this.brandDetail.backgroundColor = this.rgbToHex(color);
      }

      if (type === "button") {
        this.brandDetail.buttonColor = this.rgbToHex(color);
      }
      if (type === "text") {
        this.brandDetail.textColor = this.rgbToHex(color);
      }

      // console.log(this.rgbToHex(color), "color", this.brandDetail.backgroundColor);
      this.storeAppName();
    },
    getBrandDetail() {
      let post_data = {
        url: "/whitelabel/getBrandDetail",
      };

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          this.brandDetail = response.data.detail;
          this.instance = response.data.instance;
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="less">
.brand-setting {
  // left: 150px;
  // position: absolute;
  // width: 100%;
  // height: calc(~"100vh - 120px");
  // top: 55px;
  // padding: 30px;
  padding-bottom: 80px !important;
  .color-holder{
      justify-content: space-between;
      max-width: 600px;
      .color-feild {
    display: flex;
    align-items: center;
    margin-right: 20px;
    .el-color-picker {
      margin-left: 10px;
    }
  }
  }
  .el-upload-dragger {
    background-color: transparent;
    border: none;
    border-radius: 0;
    width: auto;
    height: auto;
    text-align: left;
    cursor: pointer;
    position: relative;
    overflow: unset;
    margin-right: 20px;
  }
  .el-upload-dragger {
    border: none !important;
    height: auto !important;
  }

  .el-upload.el-upload--text .el-upload__input {
    display: none !important;
  }

  .domain-type {
    flex-wrap: wrap;
    flex: 0 0 35%;

    &.Verified {
      span {
        background: #33abd814;
      }
    }

    &.Unverified {
      span {
        background: #8a8a8a3d;
      }
    }

    &.Secured {
      span {
        background: #33d84114;
      }
    }

    span {
      height: 50px;
      width: auto;
      padding: 8px 12px;
      border-radius: 4px;
      color: #33abd8;
      line-height: 40px;
      font-size: 13px;
    }
  }
  // form {
  //   padding: 20px;
  //   background: #f9fbfb;
  // }

  .expand.field {
    display: block;
  }

  .expand.field:first-child {
    max-width: 400px;
  }

  .root-domain {
    background: #fff;
    padding: 12px;
    width: 100%;
    max-width: 700px;
    justify-content: space-between;
    box-shadow: 0px 2px 6px 0px #00000026;
    border-radius: 6px;
    align-items: center;

    .secure.button button {
      color: #00acdc;
      background: #ebfaec;
      padding: 8px 12px;
      border: none;
      border-radius: 4px;
    }

    .delete.button button {
      color: #fff;
      background: #f5bbbb;
      padding: 9px 20px;
      border: none;
      border-radius: 30px;
    }
  }

  .key-code {
    background: #ebf9fa;
    border: 1px dashed #809db9;
    font-size: 20px;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    border-radius: 10px;
  }

  .key-label {
    font-weight: bold;
    padding-right: 20px;
  }

  .reset-key-label {
    font-weight: bold;
    padding-left: 20px;
    color: #d93636;
    cursor: pointer;
  }

  .link-section {
    background: #fff;
    border-radius: 4px;
    max-width: 800px;
    padding: 20px;
    box-shadow: 0px 2px 6px 0px #0000000a;

    p {
      color: #2c3e50cf;
      margin-bottom: 0;
    }

    span,
    .spand {
      margin-bottom: 20px !important;
      display: block;
    }
  }
  .mt-4 {
    margin-top: 20px !important;
  }
  
  
}
</style>
