<template>
  <div class="card-container">
    <div>
      <h3  style="color: #5b6a7a;">Training video 1</h3>
      </div>
    <div style="width: 640px; height: 440px; position: relative; ">
      <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" allowtransparency="true"
       src="https://adilo.bigcommand.com/watch/oYQmXJen"  frameborder="0" 
       allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen scrolling="no">
       </iframe>
       </div>
       <div>
      <h3 style="color: #5b6a7a;">Training video 2</h3>
      </div>
    <div style="width: 640px; height: 440px; position: relative; ">
      <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" allowtransparency="true"
       src="https://adilo.bigcommand.com/watch/i9ypiAKW"  frameborder="0" 
       allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen scrolling="no">
       </iframe>
       </div>

  <div>
      <h3 style="color: #5b6a7a;">Training video 3</h3>
      </div>
    <div style="width: 640px; height: 440px; position: relative; ">
      <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" allowtransparency="true"
       src="https://adilo.bigcommand.com/watch/Ivciuvhl"  frameborder="0" 
       allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen scrolling="no">
       </iframe>
       </div>


         <div>
      <h3 style="color: #5b6a7a;">Training video 4</h3>
      </div>
    <div style="width: 640px; height: 440px; position: relative; ">
      <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" allowtransparency="true"
       src="https://adilo.bigcommand.com/watch/O_7uBNll"  frameborder="0" 
       allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen scrolling="no">
       </iframe>
       </div>

  </div>
</template>

<script>

export default {
  data() {
    return {
    
    };
  },
  created() {
    
  },
 
  components: {
    
  },
};
</script>


.availableCode {
  display: flex;
  max-height: 400px;
  height: 200px;
  overflow: auto;
  .code {
    padding: 5px 10px;
    background: #bbecff87;
    margin: 15px;
    border-radius: 5px;
  }
  .pendingCodes,
  .usedCodes {
    flex-wrap: wrap;
    flex: 0 0 50%;
  }
}
.toolbar__search {
  /*margin-right: 31px;*/

  .search__icon {
    box-sizing: border-box;
  }

  .filter__search {
    input.el-input__inner {
      height: 32px;
      font-size: 13px;
    }

    &:focus-within {
    }
  }
}

.filter__search {
  display: flex;
  align-items: center;
  border: 1px solid #0a0a0a;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;

  .el-input-group__prepend {
    margin-right: 0 !important;
    display: block;
    width: auto;
    background-color: transparent;
    line-height: 0;
    border: 0;
    padding-right: 8px;
    padding-left: 8px;

    svg {
      width: 16px;
    }
  }

  input.el-input__inner {
    box-sizing: border-box;
    width: 0;
    height: 32px;
    padding-left: 0;
    padding-right: 0;
    font-size: 13px;
    border: 0;
    transition: all 0.3s ease-out;
  }

  svg {
    path {
      fill: #0a0a0a;
    }
  }

  &:focus-within,
  &:hover {
    border-color: #0a0a0a;

    .el-input-group__prepend {
      svg {
        path {
          fill: #0a0a0a;
        }
      }
    }

    input.el-input__inner {
      width: 180px;
      padding-right: 10px;
    }

    @media (max-width: 960px) {
      input.el-input__inner {
        width: 100px;
      }
    }
  }
}

.textCapital {
  text-transform: capitalize;
}
.users-main {
  padding: 40px;
  top: 55px;
  position: absolute;
  width: 100%;
  overflow: auto;
}
.user-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .left-side {
    .title {
      font-weight: bold;
      font-size: 22px;
    }
    .total {
      margin-left: 15px;
      font-weight: normal;
    }
  }
  .el-button--custom {
    font-weight: bolder;
  }
  .right-side {
    display: flex;
    align-items: center;
    .text-uppercase {
      font-weight: 700;
    }
    /*div {*/
    /*  margin-right: 20px;*/
    /*}*/
    .button__upload {
      padding: 10px 25px 9px;
      background-color: #0044b1;
      border: none;
      font-size: 16px;
      line-height: 19px;
      color: white;
      border-radius: 5px;
    }
  }
}
.user-list-table {
  min-width: 900px;
  p {
    margin-bottom: 5px;
  }
  .table-header {
    display: flex;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  .contact-row {
    width: 19%;
    p.name {
      font-weight: bold;
    }
    p {
      margin-bottom: 5px;
      color: #2c3e50;
    }
  }
  .status-row {
    width: 10%;
  }
  .plan-row {
    width: 10%;
  }
  .joined-row {
    width: 10%;
  }
  .last-activity-row {
    width: 17%;
  }
  .asset-row {
    width: 17%;
  }
  .table-body {
    .plan-row,
    .joined-row,
    .last-activity-row,
    .asset-row {
      color: #485050cf;
    }
  }
  .action-row {
    width: 17%;
    display: inline-grid;
    button {
      background: none;
      border: 0px;
      text-align: left;
    }
  }
  .table-row {
    display: flex;
    padding-bottom: 30px;
    .actions {
      display: block;
    }
  }
}
.color-red {
  color: #d93636;
}
.color-pink {
  color: #ea00d8;
}
.color-green {
  color: #0bd603;
}
.color-blue {
  color: #0bacdb;
}
.text-white {
  color: var(--textThemeColor);
}
</style>
