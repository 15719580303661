var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-container setting-user-crm"},[_c('h2',{staticClass:"whitelable-page-heading"},[_vm._v("Users/CRM Sync")]),_c('div',{staticClass:"user-header"},[_c('div',{staticClass:"right-side"},[_c('div',{staticClass:"email-head d-flex"},[(_vm.crms.length === 1 || _vm.mailers.connected.length > 0)?_c('el-dropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.uploadButtonVisible),expression:"uploadButtonVisible"}],staticClass:"action__dropdown project-controls",attrs:{"trigger":"click","size":"medium","placement":"bottom"},on:{"command":_vm.uploadAudioVideoCommand}},[_c('el-button',{attrs:{"type":"primary","plain":""}},[_vm._v(" "+_vm._s(_vm.activeCrm.name)+" "),_c('i',{staticClass:"el-submenu__icon-arrow el-icon-arrow-down"})]),_c('span',{staticClass:"el-dropdown-link"}),_c('el-dropdown-menu',{staticClass:"slate-dropdown custom_dropdown dropdown_bottom_200",staticStyle:{"border":"1px solid var(--color-blue)","border-radius":"10px","padding":"10px"},attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.crms),function(crm){return _c('el-dropdown-item',{key:crm.id,attrs:{"command":crm.name}},[_vm._v(" Disconnect ")])}),1)],1):_vm._e()],1),(_vm.crms.length === 0 && _vm.mailers.connected.length === 0)?_c('div',[_c('button',{staticClass:"el-button el-button--primary is-plain el-dropdown-selfdefine",on:{"click":_vm.newIntegration}},[_vm._v(" Connect New CRM ")])]):_vm._e()])]),(_vm.mailers.connected.length > 0)?_c('div',[_c('div',[_c('p',{staticClass:"head-title mr-3"},[_vm._v("On Sign Up")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('label',{staticClass:"key-label"},[_vm._v("Add/Update to list")]),_c('el-select',{attrs:{"placeholder":"Select list"},model:{value:(_vm.setting.signup_list),callback:function ($$v) {_vm.$set(_vm.setting, "signup_list", $$v)},expression:"setting.signup_list"}},[_vm._l((_vm.email_providers),function(group){return (
                group !== null &&
                group.mailer === 'mailchimp' &&
                _vm.activeCrm.name === 'Mailchimp'
              )?_c('el-option-group',{key:group.mailer,attrs:{"label":_vm.getNicename(group.mailer)}},_vm._l((group.lists),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":group.mailer + '_' + item.id}})}),1):_vm._e()}),_vm._l((_vm.email_providers),function(group){return (
                group !== null &&
                group.mailer === 'aweber' &&
                _vm.activeCrm.name === 'Aweber'
              )?_c('el-option-group',{key:group.mailer,attrs:{"label":_vm.getNicename(group.mailer)}},_vm._l((group.lists),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":group.mailer + '_' + item.id}})}),1):_vm._e()})],2)],1),_c('label',{staticClass:"key-label"},[_vm._v("Add Tag")]),_c('el-col',{attrs:{"span":12}},[_c('el-select',{attrs:{"multiple":"","filterable":"","allow-create":"","default-first-option":"","placeholder":"Add tags"},model:{value:(_vm.setting.signup_tags),callback:function ($$v) {_vm.$set(_vm.setting, "signup_tags", $$v)},expression:"setting.signup_tags"}})],1)],1),_c('div',{staticClass:"plan-tags"},[_c('label',{staticClass:"key-label"},[_vm._v("If Plan tags")]),_vm._l((this.plans),function(plan){return _c('div',{staticClass:"mt-4"},[_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('label',{staticClass:"key-label"},[_vm._v(_vm._s(plan.plan_name))])]),_c('el-col',{attrs:{"span":16}},[_c('el-select',{attrs:{"multiple":"","filterable":"","allow-create":"","default-first-option":"","placeholder":"Add tags"},model:{value:(plan.tags),callback:function ($$v) {_vm.$set(plan, "tags", $$v)},expression:"plan.tags"}})],1)],1)],1)})],2),_c('p',{staticClass:"head-title mr-3"},[_vm._v("On Cancellation")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('label',{staticClass:"key-label"},[_vm._v("Add/Update to list")]),_c('el-select',{attrs:{"placeholder":"Select list"},model:{value:(_vm.setting.cancelation_list),callback:function ($$v) {_vm.$set(_vm.setting, "cancelation_list", $$v)},expression:"setting.cancelation_list"}},[_vm._l((_vm.email_providers),function(group){return (
                group !== null &&
                group.mailer === 'mailchimp' &&
                _vm.activeCrm.name === 'Mailchimp'
              )?_c('el-option-group',{key:group.mailer,attrs:{"label":_vm.getNicename(group.mailer)}},_vm._l((group.lists),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":group.mailer + '_' + item.id}})}),1):_vm._e()}),_vm._l((_vm.email_providers),function(group){return (
                group !== null &&
                group.mailer === 'aweber' &&
                _vm.activeCrm.name === 'Aweber'
              )?_c('el-option-group',{key:group.mailer,attrs:{"label":_vm.getNicename(group.mailer)}},_vm._l((group.lists),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":group.mailer + '_' + item.id}})}),1):_vm._e()})],2)],1),_c('label',{staticClass:"key-label"},[_vm._v("Add Tag")]),_c('el-col',{attrs:{"span":12}},[_c('el-select',{attrs:{"multiple":"","filterable":"","allow-create":"","default-first-option":"","placeholder":"Add tags"},model:{value:(_vm.setting.cancelation_tags),callback:function ($$v) {_vm.$set(_vm.setting, "cancelation_tags", $$v)},expression:"setting.cancelation_tags"}})],1)],1),_c('p',{staticClass:"head-title mr-3"},[_vm._v("On Ban/Suspension")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('label',{staticClass:"key-label"},[_vm._v("Add/Update to list")]),_c('el-select',{attrs:{"placeholder":"Select list"},model:{value:(_vm.setting.suspension_list),callback:function ($$v) {_vm.$set(_vm.setting, "suspension_list", $$v)},expression:"setting.suspension_list"}},[_vm._l((_vm.email_providers),function(group){return (
                group !== null &&
                group.mailer === 'mailchimp' &&
                _vm.activeCrm.name === 'Mailchimp'
              )?_c('el-option-group',{key:group.mailer,attrs:{"label":_vm.getNicename(group.mailer)}},_vm._l((group.lists),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":group.mailer + '_' + item.id}})}),1):_vm._e()}),_vm._l((_vm.email_providers),function(group){return (
                group !== null &&
                group.mailer === 'aweber' &&
                _vm.activeCrm.name === 'Aweber'
              )?_c('el-option-group',{key:group.mailer,attrs:{"label":_vm.getNicename(group.mailer)}},_vm._l((group.lists),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":group.mailer + '_' + item.id}})}),1):_vm._e()})],2)],1),_c('label',{staticClass:"key-label"},[_vm._v("Add Tag")]),_c('el-col',{attrs:{"span":12}},[_c('el-select',{attrs:{"multiple":"","filterable":"","allow-create":"","default-first-option":"","placeholder":"Add tags"},model:{value:(_vm.setting.suspension_tags),callback:function ($$v) {_vm.$set(_vm.setting, "suspension_tags", $$v)},expression:"setting.suspension_tags"}})],1)],1),_c('p',{staticClass:"head-title mr-3"},[_vm._v("On Delete")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('label',{staticClass:"key-label"},[_vm._v("Add/Update to list")]),_c('el-select',{attrs:{"placeholder":"Select list"},model:{value:(_vm.setting.delete_list),callback:function ($$v) {_vm.$set(_vm.setting, "delete_list", $$v)},expression:"setting.delete_list"}},[_vm._l((_vm.email_providers),function(group){return (
                group !== null &&
                group.mailer === 'mailchimp' &&
                _vm.activeCrm.name === 'Mailchimp'
              )?_c('el-option-group',{key:group.mailer,attrs:{"label":_vm.getNicename(group.mailer)}},_vm._l((group.lists),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":group.mailer + '_' + item.id}})}),1):_vm._e()}),_vm._l((_vm.email_providers),function(group){return (
                group !== null &&
                group.mailer === 'aweber' &&
                _vm.activeCrm.name === 'Aweber'
              )?_c('el-option-group',{key:group.mailer,attrs:{"label":_vm.getNicename(group.mailer)}},_vm._l((group.lists),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":group.mailer + '_' + item.id}})}),1):_vm._e()})],2)],1),_c('label',{staticClass:"key-label"},[_vm._v("Add Tag")]),_c('el-col',{attrs:{"span":12}},[_c('el-select',{attrs:{"multiple":"","filterable":"","allow-create":"","default-first-option":"","placeholder":"Add tags"},model:{value:(_vm.setting.delete_tags),callback:function ($$v) {_vm.$set(_vm.setting, "delete_tags", $$v)},expression:"setting.delete_tags"}})],1)],1)],1),_c('div',{staticClass:"mt-5 text-right mb-5"},[_c('button',{staticClass:"btn btn-primary btn-primary-outline",attrs:{"type":"button"},on:{"click":_vm.storeCRM}},[_vm._v(" Save ")])])]):_vm._e(),_c('div',{staticClass:"settings-crm-integration",attrs:{"id":"settings-integration"}},[_c('new-integration',{attrs:{"integration-dialog":_vm.integrationDialog,"user":_vm.user}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }