<template>
  <div class="topbar" id="top-bar" v-if="user && showMenu ">
    <div id="toggle" @click="select()">
      <div class="span" id="top" :class="{ active: isActiveMainMenu }"></div>
      <div class="span" id="middle" :class="{ active: isActiveMainMenu }"></div>
      <div class="span" id="bottom" :class="{ active: isActiveMainMenu }"></div>
    </div>
    <div id="main_menu" :class="{ active: isActiveMainMenu }">
      <el-menu
        :router="true"
        @select="handleSelect"
        class="el-menu-main"
        mode="horizontal"
      >
        <div class="left">
          <!-- <el-menu-item
            :key="1"
            class="logo-item"
            index="/"
            style="padding-left: 20px"
            v-if="
              user.whitelabel_setting === null ||
              (user.whitelabel_setting !== null &&
                user.whitelabel_setting.app_logo === null)
            "
          >
            <router-link to="/" class="logo-link"
              ><img
                alt="4KPlayer"
                class="logo"
                src="../../assets/4kplayerlogo.svg"
            /></router-link>
          </el-menu-item> -->
          <el-menu-item
            :key="1"
            class="logo-item"
            index="/"
            style="padding-left: 20px"
            v-if="
              user.whitelabel_setting !== null &&
              user.whitelabel_setting.app_logo !== null
            "
          >
            <router-link to="/" class="logo-link"
              ><img
                alt="Mbsm"
                class="mbsm_main_logo"
                :src="user.whitelabel_setting.app_logo"
                height="40"
            /></router-link>
          </el-menu-item>
          <el-menu-item v-if="mbsm_brand_name !== null ">
            {{mbsm_brand_name}}
          </el-menu-item>
        </div>

        <div v-if="isMobile" class="logo-img">
          <router-link to="/">
            <img
              alt="4KPlayer"
              class="logo"
              src="../../assets/4kplayerlogo.svg"
              v-if="
                user.whitelabel_setting === null ||
                (user.whitelabel_setting !== null &&
                  user.whitelabel_setting.app_logo === null)
              "
            />

            <img
              alt="4KPlayer"
              class="logo"
              :src="user.whitelabel_setting.app_logo"
              height="40"
              v-if="
                user.whitelabel_setting !== null &&
                user.whitelabel_setting.app_logo !== null
              "
            />
          </router-link>
        </div>
        <div
          class="center"
          :class="{ active: isActiveMainMenu }"
          v-if="activeTabName !== null"
        >
          <!-- <el-menu-item
            class="main-menu-item"
            v-if="user.whitelabel_setting && user.whitelabel_setting.owner"
            :class="{ 'is-active': activeTabName === 'whitelabel' }"
            :to="{ path: '/whitelabel/dashboard' }"
            index="/whitelabel/dashboard"
          >
            <a href="/whitelabel/dashboard" @click.prevent>Whitelabel</a>
          </el-menu-item> -->
          <!-- <el-menu-item
            v-if="
              !user.whitelabel_setting ||
              (user.whitelabel_setting && !user.whitelabel_setting.owner)
            "
            class="main-menu-item"
            :class="{ 'is-active': activeTabName === 'power-player' }"
            :to="{ path: '/' }"
            index="/"
          >
            <a href="/" @click.prevent>My Videos</a>
          </el-menu-item>

          <el-menu-item
            v-if="
              !user.whitelabel_setting ||
              (user.whitelabel_setting && !user.whitelabel_setting.owner)
            "
            class="main-menu-item"
            :class="{ 'is-active': activeTabName === 'settings' }"
            :to="{ path: '/settings' }"
            index="/settings"
          >
            <a href="/settings" @click.prevent>Settings</a>
          </el-menu-item>
          <el-menu-item
            v-if="
              !user.whitelabel_setting ||
              (user.whitelabel_setting && !user.whitelabel_setting.owner)
            "
            class="main-menu-item"
            :class="{ 'is-active': activeTabName === 'help' }"
            index="/"
          >
            <a href="https://help.bigcommand.com" target="_blank">Help</a>
          </el-menu-item> -->
        </div>

        <div class="right" v-if="!showMbsmUserProfile">
          <el-submenu class="profile-menu-item sub-menu" index="">
            <template slot="title">
              <div class="user-info">
                <div class="user-name">{{ user.full_name }}</div>
                <div class="user-company text-capitalize">
                  {{ user.settings.company }}
                </div>
              </div>
              <img
                :src="user.settings.avatar"
                alt=""
                class="img-circle profile-avatar"
                v-if="user.settings"
                width="42"
              />
            </template>

            <router-link to="/settings">
              <el-menu-item
                class="text-center"
                index="settings"
                style="height: auto"
              >
                <img
                  :src="croppa(user.settings.avatar, 150, 150)"
                  alt=""
                  class="img-circle row-space-top-2"
                  height="60"
                  width="60"
                />
                <div class="user-identifire">
                  <span>{{ user.full_name }}</span>
                </div>
              </el-menu-item>
            </router-link>
            <!-- <router-link to="/whitelabel/settings/branding" v-if="whitelabelBrandDetail !== null">
              <el-menu-item
                class="text-center"
                index="settings"
                style="height: auto"
              >
                <img
                  :src="croppa(user.settings.avatar, 150, 150)"
                  alt=""
                  class="img-circle row-space-top-2"
                  height="60"
                  width="60"
                />
                <div class="user-identifire">
                  <span>{{ user.full_name }}</span>
                </div>
                
              </el-menu-item>
            </router-link> -->

            <!--                        <router-link to="/contacts">-->
            <!--                            <el-menu-item index="contacts">-->
            <!--                                <span>Subscribers</span>-->
            <!--                            </el-menu-item>-->
            <!--                        </router-link>-->
            <!--                        <router-link to="/settings/user_referral">-->
            <!--                            <el-menu-item index="contacts">-->
            <!--                                <span>Referral Earnings</span>-->
            <!--                            </el-menu-item>-->
            <!--                        </router-link>-->

            <router-link to="/logout">
              <el-menu-item index="logout">
                <span>Logout</span>
              </el-menu-item>
            </router-link>
          </el-submenu>
        </div>
        <el-dropdown trigger="click" v-if="showMbsmUserProfile">
          <div class="profile-detail-holder">
              <div class="user-info">
                <div class="user-name">{{ user.full_name }}</div>
                <div>
                  <img
                  :src="user.settings.avatar"
                  alt=""
                  class="img-circle profile-avatar"
                  v-if="user.settings"
                  width="42"
                />
                </div>
                <i class="el-submenu__icon-arrow el-icon-arrow-down"></i>
              </div>
              
          </div>
          <el-dropdown-menu class="profile-menu-items" slot="dropdown">
            <el-dropdown-item class="account-detail-menu">
              <p >{{ user.full_name }}<br>{{ user.email }}</p>
            </el-dropdown-item>
            <router-link to="/whitelabel/settings/branding">
            <el-dropdown-item>Settings</el-dropdown-item>
            </router-link>
            <router-link to="/logout">
            <el-dropdown-item><i class="el-icon-switch-button"></i>Logout</el-dropdown-item>
            </router-link>
          </el-dropdown-menu>
        </el-dropdown>
      </el-menu>
      <div class="hamb"></div>
    </div>
    <div class="failed-message" v-if="user.billing_status == 'Failed'">
      <p>
        Your account renewal failed. Please update the payment method on file
        and pay invoice
        <el-link @click="openInvoicePage(user.failed_payment.invoice)">{{
          user.failed_payment.invoice.receipt_id
        }}</el-link>
        to avoid service disruption on {{ user.failed_payment.cancel_date }}.
      </p>
    </div>
  </div>
</template>
<style lang="less" src="../../assets/less/custom/app-header.less"></style>
<script>
import $ from "jquery";
export default {
  name: "app-header",
  props: ["user", "whitelabelBrandDetail"],
  data: () => ({
    notifications: [],
    notificationsLoading: true,
    index: 0,
    searchQuery: "",
    currentTab: "/",
    activeTabName: "dashboard",
    isActiveMainMenu: false,
    isMobile: false,
    blockedRoutes: [
      "RoomPage",
      "SnapSharedRecording",
      "SnapPageView",
      "PublicVideo",
      "SubscriptionCheckout",
    ],
    showMenu: true,
    mbsm_brand_name: null,
    showMbsmUserProfile: false
  }),

  created() {
      if(process.env.VUE_APP_WHITELABEL_DOMAIN === location.host){
        this.mbsm_brand_name = 'MBSM';
        this.showMbsmUserProfile = true;
      }else{
        if(this.whitelabelBrandDetail !== null && this.whitelabelBrandDetail.app_name !== null && this.whitelabelBrandDetail.app_name !== ''){
        this.mbsm_brand_name = this.whitelabelBrandDetail.app_name;
        }else{
          this.mbsm_brand_name = 'MBSM';
        }
      }
    this.currentTab = window.location.pathname;
    this.mobileCheck();
    let route = this.$route.name;
    this.publicUrl(route);

    
  },

  computed: {
    projectActive() {
      return this.currentTab.indexOf("projects") !== -1;
    },

    searchActive() {
      return false;
    },
  },

  beforeDestroy() {
    window.vEvent.stop("app-header-active-select", this.handleSelect);
  },

  mounted() {
    window.vEvent.listen("app-header-active-select", this.handleSelect);

    this.selectedTab();
  },

  methods: {
    selectedTab() {
      if (this.currentTab === "/") {
        this.activeTabName = "dashboard";
      } else {
        if (
          this.currentTab.indexOf("stage") > -1 &&
          this.currentTab.indexOf("settings") === -1
        ) {
          this.activeTabName = "stage";
        } else if (this.currentTab.indexOf("contacts") > -1) {
          this.activeTabName = "contacts";
        } else if (this.currentTab.indexOf("analytics") > -1) {
          this.activeTabName = "analytics";
        } else if (this.currentTab.indexOf("settings") > -1) {
          this.activeTabName = "settings";
        } else if (this.currentTab === "/help") {
          this.activeTabName = "help";
        } else {
          this.activeTabName = "project";
        }
      }
    },

    handleSearchOpen() {
      this.$emit("openSearch");
    },

    handleSelect(index) {
      this.currentTab = index;

      if (index === "/contacts") {
        window.vEvent.fire("go-contacts-list-page");
      }

      this.activeTabName = null;

      this.$nextTick(() => {
        this.selectedTab();
      });
    },

    select: function () {
      this.isActiveMainMenu = !this.isActiveMainMenu;
    },

    mobileCheck() {
      let BC_onMobile = false;
      if (
        window.screen.availWidth <= 960 ||
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        BC_onMobile = true;
      }

      this.isMobile = BC_onMobile;
    },
    openInvoicePage(invoice) {
      let path = "";
      path = {
        name: "BillingReceipt",
        params: { section: "billing", receiptID: invoice.id },
      };

      this.$store.commit("setInvoiceDetails", invoice);
      this.$router.push(path);
    },
    publicUrl(currentRoute) {
      
      if (this.blockedRoutes.includes(currentRoute)) {
        if (currentRoute == "SnapPageView") {
          setTimeout(() => {
            if (
              this.$store !== undefined &&
              this.$store.getters.isAuthenticated != null
            ) {
              this.showMenu = true;
            } else {
              this.showMenu = false;
            }
          }, 1000);
        } else {
          this.showMenu = false;
        }
      }
        else if(currentRoute.startsWith('FirstAccess')){
          this.showMenu = false;
        
      } else {
        this.showMenu = true;
      }
    },
  },
  components: {},
  watch: {
    $route(to, from) {
      this.publicUrl(to.name);
    },
  },
};
</script>
<style lang="less">

#main_menu{
  padding-top: 20px;
  background: #fff;
}
.profile-detail-holder{
    padding: 5px 20px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    transition: all 0.2s;
    cursor: pointer;
    &:hover{
      background-color: #9ed9ff4f;
    }
  .user-info{
    display: flex;
    justify-content: center;
    align-items: center;
    .user-name{
      padding-right: 10px;
      + div{
        padding-right: 10px;
      }
    }

  }
}

.profile-menu-items{
  .el-dropdown-menu__item{
    &.account-detail-menu{
      line-height: 20px !important;
      cursor: default;
      &:hover{
        background-color: transparent !important;
      }
    }
  }
  &.el-dropdown-menu{
    border-radius: 10px;
    li{
      line-height: 44px;
      &:hover{
          background-color: #9ed9ff4f !important;
      }
    }
  }
}
</style>