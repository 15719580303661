<template>
  <div>
    <div class="launch-whitelabel">
      <div v-if="!showSuccess">
        <!-- <div v-if="!showSuccess"> -->
        <p>Hold on we are launching your software in the cloud!!</p>

        <!-- <el-progress :percentage="50" :indeterminate="true" /> -->
        <div class="progress progress-infinite">
          <div class="progress-bar3"></div>
        </div>
      </div>
      <div v-if="showSuccess">
        <!-- <div v-if="showSuccess"> -->
        <h3 class="pb-2">Success!</h3>
        <p>
          Your new app url: <a :href="appUrl" target="_blank">{{ appUrl }}</a>
        </p>
        <p>
          User login url: <a :href="loginUrl" target="_blank">{{ loginUrl }}</a>
        </p>
        <p>
          User register url:
          <a :href="registerUrl" target="_blank">{{ registerUrl }}</a>
        </p>
        <p>
          User reset password url:
          <a :href="resetPassUrl" target="_blank">{{ resetPassUrl }}</a>
        </p>

        <div class="mt-5">
          <el-button type="custom" class="btn-primary" @click="redirect">
            Go To My Control Panel
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FirstAccessLaunch",
  data() {
    return {
      showSuccess: false,
      appUrl: "",
      loginUrl: "",
      registerUrl: "",
      resetPassUrl: "",
    };
  },
  props: ["user"],
  created() {
    var $this = this;
    // let x = Math.floor(Math.random() * 5 + 1);
    // setInterval(function () {
    //   $this.showSuccess = true;
    // }, x * 1000);

    var statusInterval = setInterval(function () {
      let post_data = {
        url: "/whitelabel/custom-domain/check",
      };
      $this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          // console.log(response, response.data);
          if (response.data.status) {
            $this.showSuccess = true;
             $this.user.whitelabel_licence = response.data.whitelabel_licence;
            if (
              typeof $this.user.whitelabel_licence !== "undefined" &&
              $this.user.whitelabel_licence !== null
            ) {
              var domain = $this.user.whitelabel_licence.instance;

              if ($this.user.whitelabel_licence.custom_domain !== null) {
                domain = $this.user.whitelabel_licence.custom_domain;
              }
              // domain = 'mbsm.g2.gopanear.com';
              $this.appUrl = "https://" + domain;
              $this.loginUrl = "https://" + domain + "/login";
              $this.registerUrl = "https://" + domain + "/register";
              $this.resetPassUrl = "https://" + domain + "/forgot-password";
            }
            clearInterval(statusInterval);
          }
        })
        .catch((error) => {});
    }, 1000);

    console.log(this.user.whitelabel_licence, "=======");
  },
  methods: {
    redirect() {
      this.$router.push("/whitelabel/dashboard");
      window.location.reload();
    },
  },
};
</script>
<style lang="less" scoped>
.pb-2 {
  padding-bottom: 20px;
}
a {
  color: #053083;
  text-decoration: none;
}
.launch-whitelabel {
  margin: 50px auto;
  width: 100%;
  // border: 3px solid green;
  padding: 10px;
}
@keyframes indeterminate {
  0% {
    left: -100%;
  }
  to {
    left: 100%;
  }
}
.el-progress .el-progress-bar__inner {
  animation-duration: 3s;
  transform: translateZ(0);
  animation: indeterminate 3s infinite;
  transition: width 0.6s ease;
  white-space: nowrap;
}
.el-progress__text {
  display: none;
}
.progress {
  // padding: 6px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25),
    0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar {
  height: 18px;
  background-color: #ee303c;
  border-radius: 4px;
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-striped .progress-bar {
  background-color: #fcbc51;
  width: 100%;
  background-image: linear-gradient(
    45deg,
    rgb(252, 163, 17) 25%,
    transparent 25%,
    transparent 50%,
    rgb(252, 163, 17) 50%,
    rgb(252, 163, 17) 75%,
    transparent 75%,
    transparent
  );
  animation: progressAnimationStrike 6s;
}

@keyframes progressAnimationStrike {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.progress2 {
  padding: 6px;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25),
    0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar2 {
  height: 18px;
  border-radius: 30px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.05)
  );
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-moved .progress-bar2 {
  width: 85%;
  background-color: #ef476f;
  animation: progressAnimation 6s;
}

@keyframes progressAnimation {
  0% {
    width: 5%;
    background-color: #f9bcca;
  }
  100% {
    width: 85%;
    background-color: #ef476f;
  }
}

// $green: #8df3fd;
// #5ac8fa: #5ac8fa;
// #007aff: #007aff;
// #7DC8E8: #7DC8E8;
// #95c5ff: #95c5ff;
// #b0d2fb: #b0d2fb;

.progress-bar3 {
  height: 18px;
  border-radius: 4px;
  background-image: linear-gradient(
    to right,
    #8df3fd,
    #5ac8fa,
    #007aff,
    #7dc8e8,
    #95c5ff,
    #b0d2fb
  );
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-infinite .progress-bar3 {
  width: 100%;
  background-image: linear-gradient(
    to right,
    #8df3fd,
    #5ac8fa,
    #007aff,
    #7dc8e8,
    #95c5ff,
    #b0d2fb
  );
  animation: colorAnimation 5s infinite;
}

@keyframes colorAnimation {
  0% {
    background-image: linear-gradient(
      to right,
      #8df3fd,
      #5ac8fa,
      #007aff,
      #7dc8e8,
      #95c5ff,
      #b0d2fb
    );
  }
  20% {
    background-image: linear-gradient(
      to right,
      #5ac8fa,
      #007aff,
      #7dc8e8,
      #95c5ff,
      #b0d2fb,
      #8df3fd
    );
  }
  40% {
    background-image: linear-gradient(
      to right,
      #007aff,
      #7dc8e8,
      #95c5ff,
      #b0d2fb,
      #8df3fd,
      #5ac8fa
    );
  }
  60% {
    background-image: linear-gradient(
      to right,
      #7dc8e8,
      #95c5ff,
      #b0d2fb,
      #8df3fd,
      #5ac8fa,
      #007aff
    );
  }
  100% {
    background-image: linear-gradient(
      to right,
      #95c5ff,
      #b0d2fb,
      #8df3fd,
      #5ac8fa,
      #007aff,
      #7dc8e8
    );
  }
}
.btn-primary {
  color: #053083 !important;
  background: transparent !important;
  border: 1px solid #053083 !important;
}
</style>
