<template>
  <div class="info-holder">
    <h1>Apps you own</h1>
    <img alt="4KPlayer" class="logo" src="../../assets/4kplayerlogo.svg" />
    <div class="details">
      <p v-if="user && !user.is_first_login">
        <router-link :to="{ name: 'WhiteBoardDashBoard' }"
          >Click here</router-link
        >
        to access your instance
      </p>
      <p v-else>
        <span style="color: #4cbfe3; cursor: pointer" @click="checkSubscription"
          >Click here</span
        >
        to access your instance
      </p>
      <!-- <router-link :user="user" :to="{name : 'FirstAccess.branding'}"> -->
      <!-- <p>Software sendbox demo {{ '</>' }}</p> -->
    </div>

    <div v-if="!show" style="text-align: left; width: 450px; margin: 15px auto">
      <div>
        <div v-if="plan.name !== ''" style="color: #828484">
          <span style="color: #373737">Plan name: </span>{{ plan.name }}
        </div>
        <div v-if="plan.users !== ''" style="color: #828484">
          <span style="color: #373737">Number of users: </span>{{ plan.users }}
        </div>

        <div v-if="plan.date !== ''" style="color: #828484">
          <span style="color: #373737"> Date purchased: </span
          >{{ changeDateFormate(plan.date) }}
        </div>
      </div>
    </div>
    <div>
      <a href="/dist/4KPlayer Whitelabel Downloads.pdf" target="_blank"
        >Whitelabel Docs & Guides Download</a
      >
    </div>

    <el-dialog
      center
      class="el-dialog--huge dialog_border_radius"
      :visible.sync="show"
      :modalAppendToBody="false"
    >
      <div class="el-dialog__body">
        <div style="display: flex; flex-direction: column">
          You’re not subscribed to a Whitelabel license plan
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "FirstAccessInfo",
  props: {
    user: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  created() {
    let post_data = {
      url: "/whitelabel/check/suscription/" + this.user.id,
    };
    var $this = this;

    this.$store
      .dispatch("post", { ...post_data })
      .then((response) => {
        console.log(response.data.status);

        if (response.data.status) {
          if (response.data.plan) {
            this.plan.name = response.data.plan.name;
            this.plan.users = response.data.plan.users;
            this.plan.date = response.data.plan.date;
          }
        }
        // this.testConnection();
        // $this.$message({
        //     showClose: true,
        //     message: "Detail successfully saved",
        //     type: "success",
        // });
      })
      .catch((error) => {});
  },
  data() {
    return {
      show: false,
      plan: {
        name: "",
        users: "",
        date: "",
      },
    };
  },
  methods: {
    changeDateFormate(date) {
      var myArr = date.split(" ");
      var data = myArr[0].split("-");

      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var d = new Date(myArr[0]);
      var monthName = months[d.getMonth()];

      return monthName + " " + data[2] + ", " + data[0];
    },
    checkSubscription() {
      let post_data = {
        url: "/whitelabel/check/suscription/" + this.user.id,
      };
      var $this = this;

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          console.log(response.data.status);

          if (response.data.status) {
            if (response.data.plan) {
              this.plan.name = response.data.plan.name;
              this.plan.users = response.data.plan.users;
              this.plan.date = response.data.plan.date;
            }

            this.$router.push({ name: "FirstAccess.branding" });
          } else {
            $this.show = true;
          }
          // this.testConnection();
          // $this.$message({
          //     showClose: true,
          //     message: "Detail successfully saved",
          //     type: "success",
          // });
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.info-holder {
  text-align: center;
  .details {
    margin-top: 40px;
  }
  .logo {
    height: 80px;
  }
}
</style>
